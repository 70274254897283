export function arrayVideosDE(){
    const arrayVideosDE = [{
        "index": 427,
        "urlSingle": "auction-house-v3-de",
        "titulo": "Auction House v3",
        "descripcion": "In diesem Tutorial werden wir das Auction House V3 und seine neuen Funktionen ansehen, wir werden ein NFT erstellen und es listen.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/gXvpbUepJHg",
        "github": "",
        "web": "https://ergoauctions.org/",
        "youtube": "https://www.youtube.com/watch?v=gXvpbUepJHg",
        "idioma": 1
    },{
        "index": 426,
        "urlSingle": "token-mit-python-erstellen",
        "titulo": "Token mit Python erstellen",
        "descripcion": "In diesem Tutorial werden wir Python verwenden, um mit der Ergo-Blockchain zu interagieren, wir werden 1000 Einheiten eines Tokens erstellen.",
        "categoria": "Development (Python)",
        "incrustado": "https://www.youtube.com/embed/6iMymUw-aeI",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-26",
        "web": "https://github.com/mgpai22/ergpy",
        "youtube": "https://www.youtube.com/watch?v=6iMymUw-aeI",
        "idioma": 4
    },{
        "index": 425,
        "urlSingle": "erg-mit-python-senden",
        "titulo": "ERG mit Python senden",
        "descripcion": "In diesem Tutorial werden wir Python (ergpy) verwenden, um mit der Ergo-Blockchain zu interagieren, wir werden den Betrag von 0,1 ERG senden. ",
        "categoria": "Development (Python)",
        "incrustado": "https://www.youtube.com/embed/MdWeMy6ZPP0",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-25",
        "web": "https://github.com/mgpai22/ergpy",
        "youtube": "https://www.youtube.com/watch?v=MdWeMy6ZPP0",
        "idioma": 4
    },{
        "index": 424,
        "urlSingle": "ergonode-mit-mac",
        "titulo": "Ergo-Node mit Mac",
        "descripcion": "In diesem Tutorial werden wir erklären, wie man den Ergo-Node mit Mac ausführt.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/omh2ILAbpyc",
        "github": "https://github.com/ergoplatform/ergo/releases/",
        "web": "https://docs.ergoplatform.com/node/install/pi/",
        "youtube": "https://www.youtube.com/watch?v=omh2ILAbpyc",
        "idioma": 4
    },{
        "index": 423,
        "urlSingle": "nfts-bei-skyharbor",
        "titulo": "NFTs bei SkyHarbor kaufen und verkaufen",
        "descripcion": "In diesem Tutorial sehen wir, wie man NFTs bei SkyHarbor kauft und verkauft. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/JjZXIwmqqgo",
        "github": "https://github.com/skyharbor-market",
        "web": "https://www.skyharbor.io/",
        "youtube": "https://www.youtube.com/watch?v=JjZXIwmqqgo",
        "idioma": 4
    },{
        "index": 422,
        "urlSingle": "ergo-mobilewallet-verwenden",
        "titulo": "Ergo Mobile Wallet verwenden",
        "descripcion": "In diesem Tutorial verwenden wir die Ergo Mobile Wallet-App für Android. Wir werden eine neue Wallet erstellen, eine weitere aus der Seed-Phrase wiederherstellen und auch eine read-only Wallet-Adresse hinzufügen. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/jRf6k6X7XiI",
        "github": "https://github.com/ergoplatform/ergo-wallet-app",
        "web": "https://ergoplatform.org/en/ergo-wallet-app/",
        "youtube": "https://www.youtube.com/watch?v=jRf6k6X7XiI",
        "idioma": 4
    },{
        "index": 421,
        "urlSingle": "download-verwendung-safew",
        "titulo": "Download und Verwendung von SafeW",
        "descripcion": "In diesem Tutorial werden wir SafeW herunterladen, eine Wallet erstellen, eine andere wiederherstellen und beide Wallets verwenden. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/xsGCpAgBrv8",
        "github": "https://github.com/ThierryM1212/SAFEW",
        "web": "https://ergoplatform.org/en/get-erg/#Wallets",
        "youtube": "https://www.youtube.com/watch?v=xsGCpAgBrv8",
        "idioma": 4
    },{
        "index": 420,
        "urlSingle": "ergodex-mit-nautilus-verwenden",
        "titulo": "ErgoDex mit Nautilus Wallet verwenden",
        "descripcion": "In diesem Tutorial werden wir sehen, wie man ErgoDex (Jetzt heißt es Spectrum.fi) mit dem Nautilus Wallet-Konnektor verwendet, wobei zuvor die Berechtigung für den Yoroi-Konnektor entfernt werden muss. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/oxh6fkBNALM",
        "github": "https://github.com/ergolabs",
        "web": "https://spectrum.fi/",
        "youtube": "https://www.youtube.com/watch?v=oxh6fkBNALM",
        "idioma": 4
    },{
        "index": 419,
        "urlSingle": "nft-kunstler-verifizieren",
        "titulo": "NFT-Künstler verifizieren",
        "descripcion": "In diesem Tutorial sehen wir, wie man NFT-Künstler in Ergo verifiziert. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/HxJgHWHPX9g",
        "github": "https://github.com/anon-real/ErgoAuctionHouse",
        "web": "https://ergoauctions.org/",
        "youtube": "https://www.youtube.com/watch?v=HxJgHWHPX9g",
        "idioma": 4
    },{
        "index": 418,
        "urlSingle": "sigmausd-sigmarsv-kaufen",
        "titulo": "SigmaUSD und SigmaRSV kaufen",
        "descripcion": "In diesem Tutorial sehen wir, wie man SigmaUSD und SigmaRSV kauft.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/umYx97VgryA",
        "github": "https://github.com/anon-real/sigma-usd",
        "web": "https://sigmausd.io/#/",
        "youtube": "https://www.youtube.com/watch?v=umYx97VgryA",
        "idioma": 4
    },{
        "index": 417,
        "urlSingle": "ergo-paperwallet-verwenden",
        "titulo": "Ergo Paper Wallet verwenden",
        "descripcion": "In diesem Tutorial sehen wir, wie man eine Paper Wallet ohne Internetverbindung erstellt. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/hd1mrBud86Y",
        "github": "https://github.com/anon-br/ergo-paper-wallet",
        "web": "https://ergopaperwallet.org/",
        "youtube": "https://www.youtube.com/watch?v=hd1mrBud86Y",
        "idioma": 4
    },{
        "index": 416,
        "urlSingle": "ergopad-tauschen-staken",
        "titulo": "ErgoPad tauschen und staken",
        "descripcion": "In diesem Tutorial werden wir sehen, wie man ERG gegen ErgoPad auf ErgoDex (Jetzt heißt es Spectrum.fi) tauscht und wie man diese staked.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/9m-40x1TQWc",
        "github": "https://github.com/ergo-pad",
        "web": "https://www.ergopad.io/",
        "youtube": "https://www.youtube.com/watch?v=9m-40x1TQWc",
        "idioma": 4
    },{
        "index": 415,
        "urlSingle": "installieren-wallet-nautilus",
        "titulo": "Nautilus-Erweiterung installieren und Wallet erstellen",
        "descripcion": "In diesem Tutorial lernen wir, wie man die Nautilus Wallet-Erweiterung installiert und eine Wallet erstellt. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/dkThSPNiznw",
        "github": "https://github.com/capt-nemo429/nautilus-wallet/releases",
        "web": "https://ergoplatform.org/en/get-erg/#Wallets",
        "youtube": "https://www.youtube.com/watch?v=dkThSPNiznw",
        "idioma": 4
    },{
        "index": 414,
        "urlSingle": "konfigurieren-des-ergotipperbot",
        "titulo": "Konfigurieren des ErgoTipperBot",
        "descripcion": "In diesem Tutorial werden wir den ErgoTipperBot konfigurieren und einen Tip mit dem ErgoTipperBot senden. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/5FQqvDuxVzA",
        "github": "https://github.com/Luivatra/ergotipper-tokens",
        "web": "https://twitter.com/ErgoTipperBot",
        "youtube": "https://www.youtube.com/watch?v=5FQqvDuxVzA",
        "idioma": 4
    },{
        "index": 413,
        "urlSingle": "auktion-auf-ergo-auctions",
        "titulo": "Auktion auf Ergo Auctions V2 erstellen",
        "descripcion": "In diesem Tutorial sehen wir, wie man ganz einfach einen NFT prägt und wie man diesen zur Auktion und zum Direktverkauf im Ergo Auction House V2 anbietet. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/7A6VCbB9puI",
        "github": "https://github.com/anon-real/ErgoAuctionHouse",
        "web": "https://ergoauctions.org/",
        "youtube": "https://www.youtube.com/watch?v=7A6VCbB9puI",
        "idioma": 4
    },{
        "index": 412,
        "urlSingle": "erg-von-gateio",
        "titulo": "ERG von Gate.io an Wallet senden",
        "descripcion": "In diesem Tutorial werden wir ERG von Gate.io auf eine Yoroi-Wallet übertragen. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/CD8YuNQKly0",
        "github": "https://github.com/capt-nemo429/nautilus-wallet/releases",
        "web": "https://ergoplatform.org/en/get-erg/#Exchanges",
        "youtube": "https://www.youtube.com/watch?v=CD8YuNQKly0",
        "idioma": 4
    },{
        "index": 411,
        "urlSingle": "erg-bei-gateio",
        "titulo": "Erg bei Gate.io kaufen",
        "descripcion": "In diesem Tutorial kaufen wir Erg bei Gate.io, indem wir uns zuerst USDT über Binance besorgen. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/9OKutaoMe7k",
        "github": "https://ergoplatform.org/en/get-erg/#Exchanges",
        "web": "https://ergoplatform.org/en/get-erg/#Wallets",
        "youtube": "https://www.youtube.com/watch?v=9OKutaoMe7k",
        "idioma": 4
    },{
        "index": 410,
        "urlSingle": "ergowallets-verwenden",
        "titulo": "ErgoWallets.org verwenden",
        "descripcion": "Verschiedene Möglichkeiten, den Inhalt Ihrer Wallet anzuzeigen: Guthaben, Token und NFTs. Die Wallets für das Beispiel wurden zufällig ausgewählt",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/u4wq9pzxwCY",
        "github": "https://github.com/ladopixel/ergowalletv2",
        "web": "https://ergowallets.org/",
        "youtube": "https://www.youtube.com/watch?v=u4wq9pzxwCY",
        "idioma": 4
    },{
        "index": 409,
        "urlSingle": "erstellen-miniwebsite-fur-nft",
        "titulo": "Erstellen einer Mini-Website für Ihr NFT",
        "descripcion": "In diesem Tutorial werden wir eine Mini-Website erstellen, um das NFT zu zeigen, das Ihnen am besten gefällt. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/QZ8HZmWhqNA",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-2",
        "web": "https://ergotokens.org/",
        "youtube": "https://www.youtube.com/watch?v=QZ8HZmWhqNA",
        "idioma": 4
    },{
        "index": 408,
        "urlSingle": "verlosung-in-ergoraffle",
        "titulo": "Erstellen Sie eine Verlosung in ErgoRaffle",
        "descripcion": "In diesem Tutorial erstellen wir beispielhaft ein Gewinnspiel in Ergo Raffle und nehmen an einem bestehenden Gewinnspiel teil. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/UgmrEBhfeLo",
        "github": "https://github.com/ErgoRaffle",
        "web": "https://ergoraffle.com/",
        "youtube": "https://www.youtube.com/watch?v=UgmrEBhfeLo",
        "idioma": 4
    },{
        "index": 407,
        "urlSingle": "sigmausd-einlosen",
        "titulo": "SigmaUSD einlösen",
        "descripcion": "In diesem Tutorial werden wir 100 SigmaUSD gegen ERG einlösen. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/LLh0r3JmkiQ",
        "github": "",
        "web": "https://sigmausd.io/ ",
        "youtube": "https://www.youtube.com/watch?v=LLh0r3JmkiQ",
        "idioma": 4
    },{
        "index": 406,
        "urlSingle": "token-airdrop-senden",
        "titulo": "Token mit Airdrop senden",
        "descripcion": "Wir senden 10 Stück TutorialsCoins gleichzeitig an 6 Adressen. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/NXQyr8LsbyA",
        "github": "https://github.com/anon-real/ErgoUtils",
        "web": "https://ergoutils.org/#/airdrop",
        "youtube": "https://www.youtube.com/watch?v=NXQyr8LsbyA",
        "idioma": 4
    },{
        "index": 405,
        "urlSingle": "tokens-ergoutils-erstellen",
        "titulo": "Tokens mit Ergo Utils erstellen",
        "descripcion": "Wir werden mit Ergo Utils einen Token mit 1000000 Einheiten namens TutorialsCoin erstellen.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/DzPGOgD_uq0",
        "github": "https://github.com/anon-real/ErgoUtils",
        "web": "https://ergoutils.org/#/token",
        "youtube": "https://www.youtube.com/watch?v=DzPGOgD_uq0",
        "idioma": 4
    },{
        "index": 404,
        "urlSingle": "tauschen-ergodex",
        "titulo": "Tauschen mit ErgoDex",
        "descripcion": "Tauschen von 100,000 Kushtis für 8 Erdoge über ErgoDex.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/x_47yML4LBw",
        "github": "https://github.com/ergolabs",
        "web": "https://ergodex.io/",
        "youtube": "https://www.youtube.com/watch?v=x_47yML4LBw",
        "idioma": 4
    },{
        "index": 403,
        "urlSingle": "vermischen-tokens-ergomixer",
        "titulo": "Vermischen von Tokens mit dem ErgoMixer",
        "descripcion": "In diesem Tutorial lernst du, wie du den ErgoMixer downloaden und Ergo Token mixen kannst. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/yWS5TWO8G8g",
        "github": "https://github.com/ergoMixer/ergoMixBack/releases",
        "web": "https://github.com/ergoMixer/ergoMixBack/releases",
        "youtube": "https://www.youtube.com/watch?v=yWS5TWO8G8g",
        "idioma": 4
    },{
        "index": 402,
        "urlSingle": "installieren-yoroi",
        "titulo": "Yoroi-Erweiterung installieren und eine Brieftasche erstellen",
        "descripcion": "Lassen Sie uns die Yoroi-Erweiterung installieren, um Schritt für Schritt ein Portfolio zu erstellen.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/XNJ2PpAVxYo",
        "github": "https://github.com/Emurgo/yoroi-frontend",
        "web": "https://chrome.google.com/webstore/detail/yoroi/ffnbelfdoeiohenkjibnmadjiehjhajb/related",
        "youtube": "https://www.youtube.com/watch?v=XNJ2PpAVxYo",
        "idioma": 4
    },{
        "index": 401,
        "urlSingle": "basisabfrage-api-ergo",
        "titulo": "API Ergo Basisabfrage",
        "descripcion": "Rufen Sie den Wert für die Erstellungshöhe aus der Ergo-Blockchain ab und ändern Sie die Hintergrundfarbe Ihrer Website anhand dieses Wertes.",
        "categoria": "Development (HTML + JS)",
        "incrustado": "https://www.youtube.com/embed/mH169a5CNKQ",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-1",
        "web": "https://github.com/ladopixel/ErgoTutorials.com/blob/master/tutorial-1/index.html",
        "youtube": "https://www.youtube.com/watch?v=mH169a5CNKQ",
        "idioma": 4
    }]
    return arrayVideosDE
}