export function arrayVideosIT(){
    const arrayVideosIT = [{
        "index": 528,
        "urlSingle": "rosen-bridge-watcher-mac-it",
        "titulo": "Rosen Bridge Watcher con Mac",
        "descripcion": "In questa esercitazione si illustra la procedura per partecipare come watcher al Rosen Bridge. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/v-oGfcD8N44",
        "github": "https://github.com/rosen-bridge",
        "web": "https://rosen.tech/",
        "youtube": "https://www.youtube.com/watch?v=v-oGfcD8N44",
        "idioma": 5
    },{
        "index": 527,
        "urlSingle": "casa-d-aste-v3",
        "titulo": "Casa d'Aste v3",
        "descripcion": "In questo tutorial vedremo la Casa d'Aste v3, le sue nuove caratteristiche, creeremo un NFT e lo listeremo. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/owp3Xty5oBQ",
        "github": "https://github.com/ergo-auction-house/",
        "web": "https://ergoauctions.org/",
        "youtube": "https://www.youtube.com/watch?v=owp3Xty5oBQ",
        "idioma": 5
    },{
        "index": 526,
        "urlSingle": "crea-token-python-it",
        "titulo": "Crea un token con Python",
        "descripcion": "In questo tutorial useremo Python per interagire con la blockchain Ergo, creeremo 1000 unità di token.",
        "categoria": "Development (Python)",
        "incrustado": "https://www.youtube.com/embed/JIn2ws7b15o",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-26",
        "web": "https://github.com/mgpai22/ergpy",
        "youtube": "https://www.youtube.com/watch?v=JIn2ws7b15o",
        "idioma": 5
    },{
        "index": 525,
        "urlSingle": "invio-erg-python",
        "titulo": "Invio di ERG con Python",
        "descripcion": "In questo tutorial useremo Python (Ergpy) per interagire con la blockchain Ergo, invieremo l'importo di 0,1 ERG.",
        "categoria": "Development (Python)",
        "incrustado": "https://www.youtube.com/embed/HHHDwHAdjfw",
        "github": "https://github.com/mgpai22/ergpy",
        "web": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-25",
        "youtube": "https://www.youtube.com/watch?v=HHHDwHAdjfw",
        "idioma": 5
    },{
        "index": 524,
        "urlSingle": "acquista-vendi-skyharbor",
        "titulo": "Acquista e vendi NFT a SkyHarbor",
        "descripcion": "In questo tutorial vedremo come acquistare e vendere NFT su SkyHarbor. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/xjXu4hnybJ8",
        "github": "https://github.com/skyharbor-market",
        "web": "https://www.skyharbor.io/",
        "youtube": "https://www.youtube.com/watch?v=xjXu4hnybJ8",
        "idioma": 5
    },{
        "index": 523,
        "urlSingle": "nodo-ergo-mac-it",
        "titulo": "Nodo Ergo con Mac",
        "descripcion": "In questo tutorial vedremo come eseguire il nodo Ergo con Mac. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/i_Ojo4KdIs4",
        "github": "https://github.com/ergoplatform/ergo/releases/",
        "web": "https://www.oracle.com/java/technologies/java-se-glance.html",
        "youtube": "https://www.youtube.com/watch?v=i_Ojo4KdIs4",
        "idioma": 5
    },{
        "index": 522,
        "urlSingle": "usa-portafoglio-mobile-ergo",
        "titulo": "Usa il portafoglio mobile Ergo",
        "descripcion": "In questo tutorial utilizzeremo l'app Ergo Mobile Wallet per Android. Creeremo un nuovo portafoglio, ripristineremo un altro dalla frase del seme e aggiungeremo anche un indirizzo di sola lettura. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/XGoQYxcAgP4",
        "github": "https://github.com/ergoplatform/ergo-wallet-app",
        "web": "https://ergoplatform.org/en/ergo-wallet-app/",
        "youtube": "https://www.youtube.com/watch?v=XGoQYxcAgP4",
        "idioma": 5
    },{
        "index": 521,
        "urlSingle": "scambio-palo-ergopad",
        "titulo": "Scambio e staking Ergopad",
        "descripcion": "In questo tutorial vedremo come scambiare ERGs con Ergopad da Ergodex (Ora è Spectrum.fi) e come collocarli.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/pYxnPWUqQzE",
        "github": "https://github.com/ergo-pad",
        "web": "https://www.ergopad.io/",
        "youtube": "https://www.youtube.com/watch?v=pYxnPWUqQzE",
        "idioma": 5
    },{
        "index": 520,
        "urlSingle": "scarica-usa-safew",
        "titulo": "Scarica e usa SafeW",
        "descripcion": "In questo tutorial scaricheremo SafeW, creeremo un portafoglio, ripristineremo un altro e useremo entrambi i portafogli. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/6BMI9EUpXvo",
        "github": "https://github.com/thierrym1212/safew",
        "web": "https://ergoplatform.org/en/get-erg/#Wallets",
        "youtube": "https://www.youtube.com/watch?v=6BMI9EUpXvo",
        "idioma": 5
    },{
        "index": 519,
        "urlSingle": "verifica-artisti-nft",
        "titulo": "Verifica gli artisti NFT",
        "descripcion": "In questo tutorial vedremo come verificare gli artisti NFT in Ergo.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/t8gfKCBcjfo",
        "github": "https://github.com/anon-real/ErgoAuctionHouse",
        "web": "https://ergoauctions.org/",
        "youtube": "https://www.youtube.com/watch?v=t8gfKCBcjfo",
        "idioma": 5
    },{
        "index": 518,
        "urlSingle": "acquista-sigmausd-sigmarsv",
        "titulo": "Acquista SigmaUSD e SigmaRSV",
        "descripcion": "In questo tutorial vedremo come acquistare SigmaUSD e SigmaRSV.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/8X0bsa-NlkA",
        "github": "https://github.com/anon-real/sigma-usd",
        "web": "https://sigmausd.io/#/",
        "youtube": "https://www.youtube.com/watch?v=8X0bsa-NlkA",
        "idioma": 5
    },{
        "index": 517,
        "urlSingle": "portafoglio-dicarta-ergo",
        "titulo": "Usa il portafoglio di carta Ergo",
        "descripcion": "In questo tutorial vedremo come creare un portafoglio di carta senza una connessione Internet.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/TStnjN1vOm0",
        "github": "https://github.com/anon-br/ergo-paper-wallet",
        "web": "https://ergopaperwallet.org/",
        "youtube": "https://www.youtube.com/watch?v=TStnjN1vOm0",
        "idioma": 5
    },{
        "index": 516,
        "urlSingle": "ergodex-nautilus",
        "titulo": "ErgoDex con Portafoglio Nautilus",
        "descripcion": "In questo tutorial vedremo come utilizzare ErgoDex con il connettore Nautilus Wallet, rimuovendo in precedenza l'autorizzazione al connettore Yoroi. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/ifvlHqQHzPY",
        "github": "https://github.com/capt-nemo429/nautilus-wallet/releases",
        "web": "",
        "youtube": "https://www.youtube.com/watch?v=ifvlHqQHzPY",
        "idioma": 5
    },{
        "index": 515,
        "urlSingle": "installa-nautilus",
        "titulo": "Installa l'estensione Nautilus e crea un portafoglio",
        "descripcion": "In questo tutorial vedremo come installare l'estensione Nautilus Wallet e creare un portafoglio. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/2qdKw_8-dgc",
        "github": "https://github.com/capt-nemo429/nautilus-wallet/releases",
        "web": "",
        "youtube": "https://www.youtube.com/watch?v=2qdKw_8-dgc",
        "idioma": 5
    },{
        "index": 514,
        "urlSingle": "configurazione-tipperbot",
        "titulo": "Configurazione di ErgoTipperBot",
        "descripcion": "In questo tutorial configureremo e invieremo un suggerimento con ErgoTipperBot. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/VtpC0wEkBKY",
        "github": "https://github.com/Luivatra/ergotipperbackend",
        "web": "https://twitter.com/ErgoTipperBot",
        "youtube": "https://www.youtube.com/watch?v=VtpC0wEkBKY",
        "idioma": 5
    },{
        "index": 513,
        "urlSingle": "crea-asta-ergoauctionsv2",
        "titulo": "Crea asta su Ergo Auctions V2",
        "descripcion": "In questo tutorial vedremo come coniare facilmente un NFT e come metterlo all'asta e alla vendita diretta nella Ergo Auction House V2. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/0DgA8O-tshk",
        "github": "https://github.com/anon-real/ErgoAuctionHouse",
        "web": "https://ergoauctions.org/",
        "youtube": "https://www.youtube.com/watch?v=0DgA8O-tshk",
        "idioma": 5
    },{
        "index": 512,
        "urlSingle": "ritiro-gateio",
        "titulo": "Ritiro ERG da Gate.io",
        "descripcion": "In questo tutorial ritireremo ERG da Gate.io e lo trasferiremo su un portafoglio Yoroi.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/IHdLpPoenb4",
        "github": "https://ergoplatform.org/en/exchanges/",
        "web": "https://www.gate.io/",
        "youtube": "https://www.youtube.com/watch?v=IHdLpPoenb4",
        "idioma": 5
    },{
        "index": 511,
        "urlSingle": "acquista-gateio",
        "titulo": "Acquista Erg su Gate.io",
        "descripcion": "In questo tutorial acquisteremo Erg su Gate.io inviandoci USDT tramite Binance. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/7fk_zAfhaWE",
        "github": "https://ergoplatform.org/en/exchanges/",
        "web": "https://www.gate.io/",
        "youtube": "https://www.youtube.com/watch?v=7fk_zAfhaWE",
        "idioma": 5
    },{
        "index": 510,
        "urlSingle": "usa-ergowallets",
        "titulo": "Usa ErgoWallets.org",
        "descripcion": "Diversi modi per visualizzare il contenuto del tuo portafoglio: saldo, token e NFT. I portafogli per l'esempio sono stati scelti a caso.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/8izcWEi9QKg",
        "github": "https://github.com/ladopixel/ErgoWallets.org",
        "web": "https://ergowallets.org/",
        "youtube": "https://www.youtube.com/watch?v=8izcWEi9QKg",
        "idioma": 5
    },{
        "index": 509,
        "urlSingle": "crea-miniweb-nft",
        "titulo": "Crea mini web per il tuo NFT",
        "descripcion": "In questo tutorial creeremo un mini sito Web per mostrare l'NFT che ti piace di più. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/NZ8Jy2lKa-Y",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-2",
        "web": "https://github.com/ladopixel/ErgoTutorials.com/blob/master/tutorial-2/index.html",
        "youtube": "https://www.youtube.com/watch?v=NZ8Jy2lKa-Y",
        "idioma": 5
    },{
        "index": 508,
        "urlSingle": "crea-lotteria-ergoraffle",
        "titulo": "Crea una lotteria e partecipa a ErgoRaffle",
        "descripcion": "In questo tutorial creeremo una lotteria in Erfo Raffle come esempio e parteciperemo a una lotteria esistente.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/3iln_JXzjAU",
        "github": "https://github.com/ErgoRaffle",
        "web": "https://beta.ergoraffle.com/",
        "youtube": "https://www.youtube.com/watch?v=3iln_JXzjAU",
        "idioma": 5
    },{
        "index": 507,
        "urlSingle": "riscatta-sigmausd",
        "titulo": "Riscatta SigmaUSD",
        "descripcion": "In questo tutorial riscatteremo 100 SigmaUSD per ERG.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/OE_1ASGI3O8",
        "github": "https://github.com/anon-real/sigma-usd",
        "web": "https://sigmausd.io/",
        "youtube": "https://www.youtube.com/watch?v=OE_1ASGI3O8",
        "idioma": 5
    },{
        "index": 506,
        "urlSingle": "invia-token-airdrop",
        "titulo": "Invia token con Airdrop",
        "descripcion": "Invieremo 10 TutorialCoin a 6 indirizzi contemporaneamente. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/yxN38eSnADA",
        "github": "https://github.com/anon-real/ErgoUtils",
        "web": "https://ergoutils.org/#/airdrop",
        "youtube": "https://www.youtube.com/watch?v=yxN38eSnADA",
        "idioma": 5
    },{
        "index": 505,
        "urlSingle": "crea-token-ergoutils",
        "titulo": "Crea token con Ergo Utils",
        "descripcion": "Creeremo un token con 1000000 unità chiamato TutorialsCoin con Ergo Utils. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/wEeja1SIquk",
        "github": "https://github.com/anon-real/ErgoUtils",
        "web": "https://ergoutils.org/#/token",
        "youtube": "https://www.youtube.com/watch?v=wEeja1SIquk",
        "idioma": 5
    },{
        "index": 504,
        "urlSingle": "scambia-ergodex",
        "titulo": "Scambia con ErgoDex",
        "descripcion": "Scambio di 100.000 Kushti con 8 Erdoge tramite ErgoDex. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/zDqrA8mXbqo",
        "github": "https://github.com/ergolabs",
        "web": "https://ergodex.io/",
        "youtube": "https://www.youtube.com/watch?v=zDqrA8mXbqo",
        "idioma": 5
    },{
        "index": 503,
        "urlSingle": "mescolare-token-ergomixer",
        "titulo": "Come mescolare i token con ErgoMixer",
        "descripcion": "In questo tutorial imparerai come scaricare ErgoMixer e mescolare facilmente i token Ergo.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/C-GY-0oDU0Q",
        "github": "https://github.com/ergoMixer/ergoMixBack/releases",
        "web": "https://ergoplatform.org/en/software/",
        "youtube": "https://www.youtube.com/watch?v=C-GY-0oDU0Q",
        "idioma": 5
    },{
        "index": 502,
        "urlSingle": "installare-estensione-yoroi",
        "titulo": "Installare l'estensione Yoroi e creare un portafoglio",
        "descripcion": "Installiamo l'estensione Yoroi per creare un portfolio passo dopo passo.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/V5pVo1NWXXQ",
        "github": "https://github.com/Emurgo/yoroi-frontend",
        "web": "https://chrome.google.com/webstore/detail/yoroi/ffnbelfdoeiohenkjibnmadjiehjhajb/related",
        "youtube": "https://www.youtube.com/watch?v=V5pVo1NWXXQ",
        "idioma": 5
    },{
        "index": 501,
        "urlSingle": "apiergo-query-di-base",
        "titulo": "API Ergo Query di base",
        "descripcion": "Recupera il valore di Creation Height dalla blockchain di Ergo e modifica il colore di sfondo del tuo sito web con il suo valore.",
        "categoria": "Development (HTML + JS)",
        "incrustado": "https://www.youtube.com/embed/ZwjB6RNROH0",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-1",
        "web": "https://github.com/ladopixel/ErgoTutorials.com/blob/master/tutorial-1/index.html",
        "youtube": "https://www.youtube.com/watch?v=ZwjB6RNROH0",
        "idioma": 5
    }]
    return arrayVideosIT
}