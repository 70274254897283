import React, {useEffect, useState} from 'react'
import {
    Box,
    SimpleGrid,
  } from '@chakra-ui/react';
import Card from '../components/card';

import { arrayVideosEN } from '../recursos/arrayVideoEN';

export default function En(){

    const [listadoEN, setListadoEN] = useState([])
    useEffect(() => {
        const listaVideos = arrayVideosEN().map(singleVideo => 
            
                <Card 
                    key={singleVideo.index}
                    urlSingle={singleVideo.urlSingle}
                    idioma={singleVideo.idioma}
                    titulo={singleVideo.titulo}
                    categoria={singleVideo.categoria}
                    descripcion={singleVideo.descripcion}
                    urlGithub={singleVideo.github}
                    urlWeb={singleVideo.web}
                    urlYoutube={singleVideo.youtube}
                    urlVideo={singleVideo.incrustado}
                />
            
        )
        setListadoEN(listaVideos)
    }, [])
    

    return(
        <>  
            <Box mt={4}>
                <SimpleGrid columns={[1,2,3]} >
                    {listadoEN}
                </SimpleGrid>
            </Box>
        </>
    )
}