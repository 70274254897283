import React from 'react'

import { AiFillGithub } from "react-icons/ai";
import { CgWebsite } from "react-icons/cg";

export function SeleccionoMensaje(idioma, video){
    if(idioma == 1){
        return `https://twitter.com/intent/tweet?text=@ergotutorials%20for%20@ergoplatformorg%20&url=${video}%20$ERG&hashtags=ErgoTutorials,Ergo2Top10`
    }else if (idioma == 2){
        return `https://twitter.com/intent/tweet?text=@ergotutorials%20para%20@ergoplatformorg%20&url=${video}%20$ERG&hashtags=ErgoTutorials,Ergo2Top10`
    }else if (idioma == 3){
        return `https://twitter.com/intent/tweet?text=@ergotutorials%20para%20@ergoplatformorg%20&url=${video}%20$ERG&hashtags=ErgoTutorials,Ergo2Top10`
    }else if (idioma == 4){
        return `https://twitter.com/intent/tweet?text=@ergotutorials%20für%20@ergoplatformorg%20&url=${video}%20$ERG&hashtags=ErgoTutorials,Ergo2Top10`
    }else if (idioma == 5){
        return `https://twitter.com/intent/tweet?text=@ergotutorials%20per%20@ergoplatformorg%20&url=${video}%20$ERG&hashtags=ErgoTutorials,Ergo2Top10`
    }
}

// Mostrar iconos debajo de los vídeos si tienen URL
export function mostrarUrlWeb(web) {
    if(web != ''){
        return (
            <CgWebsite mx={'2px'} color={'gray'} />
            )
    }
}
export function mostrarUrlGithub(github) {
    if(github != ''){
        return (
            <AiFillGithub color={'gray'} />
            )
    }
}