import React, { useEffect, useState } from 'react'
import {
    Box,
    Heading,
    isExternal,
    Link,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Text,
    useColorModeValue,
  } from '@chakra-ui/react';

import { isMobileOnly, isTablet, isDesktop } from 'react-device-detect';

export default function Translations(){

    const colorDescripcion = useColorModeValue('gray.600', 'gray.400')
    const fondoAudios = useColorModeValue('#f3f6f9', '#1A202C')
    const colorEnlace = useColorModeValue('black', 'white')
    const cuerpoCarta = useColorModeValue('#F7FAFC', '#171923')
    
    const bgTabla = useColorModeValue('gray.300', 'gray.900')

    const [tabla, setTabla] = useState('') 

    useEffect(() => {
        
        if (isDesktop) {
            setTabla(
                <>
                <TableContainer bg={bgTabla} mt={3}>
                    <Table >
                        <TableCaption> </TableCaption>
                        <Thead>
                        <Tr>
                            <Th>Language</Th>
                            <Th>Translated tutorials</Th>
                            <Th>ERG sends</Th>
                        </Tr>
                        </Thead>
                        <Tbody>
                        <Tr>
                            <Td>Web</Td>
                            <Td>-</Td>
                            <Td>20 Σ</Td>
                        </Tr>
                        <Tr>
                            <Td>EN</Td>
                            <Td>28</Td>
                            <Td>140 Σ</Td>
                        </Tr>
                        <Tr>
                            <Td>ES</Td>
                            <Td>28</Td>
                            <Td>140 Σ</Td>
                        </Tr>
                        <Tr>
                            <Td>IT</Td>
                            <Td>28</Td>
                            <Td>140 Σ</Td>
                        </Tr>
                        <Tr>
                            <Td>PT-BR</Td>
                            <Td>26</Td>
                            <Td>130 Σ</Td>
                        </Tr>
                        <Tr>
                            <Td>DE</Td>
                            <Td>27</Td>
                            <Td>135 Σ</Td>
                        </Tr>
                        
                        </Tbody>
                        
                        <Tfoot>
                            <Tr>
                                <Th>This table is updated weekly</Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                    </TableContainer>
                </>
            )
        } else if (isMobileOnly) {
            setTabla(
                <>
                <Box maxW={'100%'}>
                <TableContainer>
                <Table size='sm'>
                    <Thead>
                    <Tr>
                        <Th>LANGUAGE</Th>
                        <Th>TUTORIALS</Th>
                        <Th>ERG SENDS</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    <Tr>
                        <Td>Web</Td>
                        <Td>-</Td>
                        <Td>20 Σ</Td>
                    </Tr>
                    <Tr>
                        <Td>EN</Td>
                        <Td>28</Td>
                        <Td>140 Σ</Td>
                    </Tr>
                    <Tr>
                        <Td>ES</Td>
                        <Td>28</Td>
                        <Td>140 Σ</Td>
                    </Tr>
                    <Tr>
                        <Td>IT</Td>
                        <Td>28</Td>
                        <Td>140 Σ</Td>
                    </Tr>
                    <Tr>
                        <Td>PT-BR</Td>
                        <Td>26</Td>
                        <Td>130 Σ</Td>
                    </Tr>
                    <Tr>
                        <Td>DE</Td>
                        <Td>27</Td>
                        <Td>135 Σ</Td>
                    </Tr>
                    </Tbody>
                    <Tfoot>
                    </Tfoot>
                </Table>
                </TableContainer>
                </Box>
            </>
            )
        }

    }, [bgTabla])

    return(
        <>
            <Box w={'100%'}
                bgColor={cuerpoCarta}
                borderRadius={10}
                mt={4} p={2}
                mb={4}
            >
                <Box bgColor={fondoAudios} p={2} mt={3}>
                    
                    <Heading 
                        mt={5}
                    >Translations</Heading>
                    
                    <Text py={4} color={colorDescripcion} fontSize={'1xl'}>
                        Translation to different languages has always been a priority for ErgoTutorials, 
                        for this reason since the beginning we have been looking for volunteers to help the website to translate some tutorials. 
                        There have always been people willing to help selflessly, but even so we have been very limited in some languages, 
                        for this reason I decided to create the raffle in 
                    <Link 
                            href={'https://ergoraffle.com/raffle/show/6b4c9159bc885885aba67d8e800e9735193b8c2d2d4e8469e2bbc8db5b9666c9'} 
                            textDecoration={'underline'} 
                            mx={2} 
                            color={colorEnlace} 
                            isExternal
                            >ErgoRaffle
                    </Link>
                    </Text>

                    <Text color={colorDescripcion} fontSize={'1xl'}>
                        In this section I would like to tell you more about the translations and the new approach.

                        The Italian, Brazilian Portuguese, German and English translators are already working to complete the missing tutorials.
                        I have decided to prioritize by asking them first if they want to continue translating, because of their selfless help at the beginning of the ErgoTutorials project.
                        Everyone's answer has been yes :) Good! We have translators up to tutorial number 50 of the current languages.
                    </Text>

                    <Text m={3} mt={5} mb={5}>
                        The 1270 ERG raised in the raffle is being distributed as follows:
                    </Text>
                    
                    {tabla}
                    
                    <Text mt={'10px'} mt={5} mb={5}>
                    The rest will be distributed as new tutorials and translations are made.
                    </Text>

                    <Text mt={5} color={colorDescripcion} fontSize={'1xl'}>
                    If you want to contribute translations of a language that is not yet available, 
                    contact us by DM on Twitter <Link href={'https://twitter.com/ergotutorials'} textDecoration={'underline'}>@ErgoTutorials</Link> and we will explain how to do it.
                    You can also contact me on Discord, I have the user LADOPIXΣL#6072.
                    </Text>

                    <Text color={colorDescripcion} fontSize={'1xl'}>
                    If you have any questions about translations, please feel free to ask without obligation, I will be happy to answer as clearly as possible.
                    </Text>
                    
                    <Text mt={'10px'} mb={5}>
                        Update the list of translators with the people who tell me they want to appear.
                    </Text>

                </Box>
            </Box>
        </>
    )
}