export function arrayVideosES(){
    const arrayVideosES = [{
        "index": 229,
        "urlSingle": "puente-rosen",
        "titulo": "Rosen Bridge Watcher con Mac",
        "descripcion": "En este tutorial realizaremos el proceso para participar como watcher en el Rosen Bridge.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/iZgYMb1vLAI",
        "github": "https://github.com/rosen-bridge/operation/blob/dev/docs/watcher/deploy-docker.md",
        "web": "https://rosen.tech/",
        "youtube": "https://www.youtube.com/watch?v=iZgYMb1vLAI",
        "idioma": 2
    },{
        "index": 228,
        "urlSingle": "casa-subastas-v3",
        "titulo": "Casa de subastas v3",
        "descripcion": "En este tutorial veremos la Auction House v3, sus nuevas funcionalidades, acuñaremos un NFT y lo listaremos.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/7Bd8iNojDtE",
        "github": "",
        "web": "https://ergoauctions.org/",
        "youtube": "https://www.youtube.com/watch?v=7Bd8iNojDtE",
        "idioma": 2
    },{
        "index": 227,
        "urlSingle": "crear-token-python",
        "titulo": "Crear token con Python",
        "descripcion": "En este tutorial vamos a utilizar Python para interactuar con la cadena de bloques de Ergo, crearemos 1000 unidades de un token.",
        "categoria": "Desarrollo (Python)",
        "incrustado": "https://www.youtube.com/embed/fL1Umv5y-QA",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/blob/master/tutorial-26/create-token.py",
        "web": "https://github.com/mgpai22/ergpy",
        "youtube": "https://www.youtube.com/watch?v=fL1Umv5y-QA",
        "idioma": 2
    },{
        "index": 226,
        "urlSingle": "envia-erg-python",
        "titulo": "Envío de ERG con Python",
        "descripcion": "En este tutorial vamos a utilizar Python (ergpy) para interactuar con la cadena de bloques de Ergo, enviaremos la cantidad de 0.1 ERG.",
        "categoria": "Desarrollo (Python)",
        "incrustado": "https://www.youtube.com/embed/bZUkZ-V6LAQ",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/blob/master/tutorial-25/send-ergs.py",
        "web": "https://github.com/mgpai22/ergpy",
        "youtube": "https://www.youtube.com/watch?v=bZUkZ-V6LAQ",
        "idioma": 2
    },{
        "index": 225,
        "urlSingle": "mercado-nfts-skyharbor",
        "titulo": "Comprar y vender NFTs en SkyHarbor",
        "descripcion": "En este tutorial veremos cómo comprar y vender NFTs en SkyHarbor.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/AQvi5KxM6YY",
        "github": "",
        "web": "https://www.skyharbor.io/",
        "youtube": "https://www.youtube.com/watch?v=AQvi5KxM6YY",
        "idioma": 2
    },{
        "index": 224,
        "urlSingle": "nodo-ergo-mac",
        "titulo": "Nodo Ergo con Mac",
        "descripcion": "En este tutorial veremos cómo ejecutar el nodo de Ergo con Mac.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/fQ_1d3LLluw",
        "github": "https://github.com/ergoplatform/ergo/releases/",
        "web": "https://docs.ergoplatform.com/node/install/",
        "youtube": "https://www.youtube.com/watch?v=fQ_1d3LLluw",
        "idioma": 2
    },{
        "index": 223,
        "urlSingle": "ergo-movil-wallet",
        "titulo": "Utilizar Ergo Mobile Wallet",
        "descripcion": "En este tutorial utilizaremos la aplicación Ergo Mobile Wallet de Android. Vamos a crear una nueva billetera, vamos a restaurar otra a partir de la frase semilla y también agregaremos una dirección de billetera en modo de solo lectura.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/5D0hH7Ke0Ns",
        "github": "https://github.com/ergoplatform/ergo-wallet-app",
        "web": "http://old.ergoplatform.org/en/mobile_wallets/",
        "youtube": "https://www.youtube.com/watch?v=5D0hH7Ke0Ns",
        "idioma": 2
    },{
        "index": 222,
        "urlSingle": "verificar-artistas-nft",
        "titulo": "Verificar artistas NFT",
        "descripcion": "En este tutorial veremos cómo verificar a los artistas de NFT en Ergo.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/ccwdxi1RYNw",
        "github": "https://github.com/anon-real/ErgoAuctionHouse",
        "web": "https://ergoauctions.org/",
        "youtube": "https://www.youtube.com/watch?v=ccwdxi1RYNw",
        "idioma": 2
    },{
        "index": 221,
        "urlSingle": "comprar-sigmausd-sigmarsv",
        "titulo": "Comprar SigmaUSD y SigmaRSV",
        "descripcion": "En este tutorial veremos cómo comprar SigmaUSD y SigmaRSV.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/za5101u_pis",
        "github": "https://github.com/anon-real/sigma-usd",
        "web": "https://sigmausd.io",
        "youtube": "https://www.youtube.com/watch?v=za5101u_pis",
        "idioma": 2
    },{
        "index": 220,
        "urlSingle": "ergo-papel",
        "titulo": "Utilizar Ergo Paper Wallet",
        "descripcion": "En este tutorial veremos cómo crear una billetera de papel sin conexión a internet. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/Z6yR6p4r4rI",
        "github": "https://github.com/anon-br/ergo-paper-wallet",
        "web": "https://ergopaperwallet.org/",
        "youtube": "https://www.youtube.com/watch?v=Z6yR6p4r4rI",
        "idioma": 2
    },{
        "index": 219,
        "urlSingle": "stakear-ergopad",
        "titulo": "Intercambiar y stakear ErgoPad",
        "descripcion": "En este tutorial veremos cómo intercambiar ERG por ErgoPad desde ErgoDEX y cómo ponerlos en staking.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/SYACESeSGt8",
        "github": "https://github.com/ergo-pad",
        "web": "https://ergopad.io/",
        "youtube": "https://www.youtube.com/watch?v=SYACESeSGt8",
        "idioma": 2
    },{
        "index": 218,
        "urlSingle": "utilizar-safew",
        "titulo": "Descargar y utilizar SafeW",
        "descripcion": "En este tutorial vamos a descargar SafeW, crearemos una billetera, restauraremos otra y utilizaremos las dos billeteras.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/CvWaVhF6dmM",
        "github": "https://github.com/ThierryM1212/SAFEW",
        "web": "https://ergoplatform.org/en/wallets/",
        "youtube": "https://www.youtube.com/watch?v=CvWaVhF6dmM",
        "idioma": 2
    },{
        "index": 217,
        "urlSingle": "ergodex-con-nautilus",
        "titulo": "ErgoDex con Nautilus Wallet",
        "descripcion": "En este tutorial veremos cómo utilizar ErgoDex con el conector de Nautilus Wallet, retirándole previamente el permiso al conector de Yoroi.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/dcl9w2rNdWU",
        "github": "https://github.com/capt-nemo429/nautilus-wallet/releases/",
        "web": "https://app.ergodex.io/",
        "youtube": "https://www.youtube.com/watch?v=dcl9w2rNdWU",
        "idioma": 2
    },{
        "index": 216,
        "urlSingle": "instalar-nautilus",
        "titulo": "Instalar la extensión Nautilus y crear una billetera",
        "descripcion": "En este tutorial veremos cómo instalar la extensión de Nautilus Wallet y crearemos una billetera.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/Muu4OO_fbR0",
        "github": "https://github.com/capt-nemo429/nautilus-wallet/releases/tag/0.1.7-beta",
        "web": "https://ergoplatform.org/en/wallets/",
        "youtube": "https://www.youtube.com/watch?v=Muu4OO_fbR0",
        "idioma": 2
    },{
        "index": 215,
        "urlSingle": "configurar-tipperbot",
        "titulo": "Configurar ErgoTipperBot",
        "descripcion": "En este tutorial vamos a configurar y a enviar una propina con ErgoTipperBot.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/d8AvBmnKBVc",
        "github": "https://github.com/Luivatra/ergotipperbackend",
        "web": "https://twitter.com/ErgoTipperBot",
        "youtube": "https://www.youtube.com/watch?v=d8AvBmnKBVc",
        "idioma": 2
    },{
        "index": 214,
        "urlSingle": "crear-subasta-ergoauctionsv2",
        "titulo": "Crear subasta en Ergo Auctions V2",
        "descripcion": "En este tutorial veremos cómo acuñar fácilmente un NFT y cómo ponerlo en subasta y venta directa en la Ergo Auction House V2.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/vq6-3SxROzg",
        "github": "https://github.com/anon-real/ErgoAuctionHouse",
        "web": "https://ergoauctions.org/",
        "youtube": "https://www.youtube.com/watch?v=vq6-3SxROzg",
        "idioma": 2
    },{
        "index": 213,
        "urlSingle": "manifiesto-ergo",
        "titulo": "El Manifiesto de Ergo",
        "descripcion": "Escucha El Manifiesto de Ergo en cualquier ocasión.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/xcgZ1d3DF74",
        "github": "https://github.com/ergoplatform/ergo",
        "web": "https://ergoplatform.org/es/blog/2021-04-26-the-ergo-manifesto/",
        "youtube": "https://www.youtube.com/watch?v=xcgZ1d3DF74",
        "idioma": 2
    },{
        "index": 212,
        "urlSingle": "retirar-gateio",
        "titulo": "Retirar ERG de Gate.io",
        "descripcion": "En este tutorial retiraremos ERG de Gate.io y lo pasaremos a una billetera de Yoroi.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/nFMjmXiLP4I",
        "github": "https://ergoplatform.org/en/exchanges/",
        "web": "https://ergoplatform.org/en/exchanges/",
        "youtube": "https://www.youtube.com/watch?v=nFMjmXiLP4I",
        "idioma": 2
    },{
        "index": 211,
        "urlSingle": "comprar-gateio",
        "titulo": "Comprar Ergos en Gate.io",
        "descripcion": "En este tutorial compraremos Ergos en Gate.io enviándonos USDT a través de Binance.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/xvfCJ3CVe3E",
        "github": "https://ergoplatform.org/en/exchanges/",
        "web": "https://ergoplatform.org/en/exchanges/",
        "youtube": "https://www.youtube.com/watch?v=xvfCJ3CVe3E",
        "idioma": 2
    },{ 
        "index": 210,
        "urlSingle": "utilizar-ergowallets",
        "titulo": "Utilizar ErgoWallets.org",
        "descripcion": "Diferentes formas para visualizar el contenido de tu billetera: saldo, tokens y NFTs. Las billeteras para el ejemplo han sido elegidas al azar.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/hbQwi_BE4N0",
        "github": "https://github.com/ladopixel/ergowallets",
        "web": "https://ergowallets.org",
        "youtube": "https://www.youtube.com/watch?v=hbQwi_BE4N0",
        "idioma": 2
    },{
        "index": 209,
        "urlSingle": "crear-miniweb-nft",
        "titulo": "Crea mini web para tu NFT",
        "descripcion": "En este tutorial vamos a crear un mini sitio web para mostrar el NFT que más te guste.",
        "categoria": "Desarrollo (HTML + JS)",
        "incrustado": "https://www.youtube.com/embed/6InUC3BTQM8",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-2",
        "web": "https://github.com/ladopixel/ErgoTutorials.com/blob/master/tutorial-2/index.html",
        "youtube": "https://www.youtube.com/watch?v=6InUC3BTQM8",
        "idioma": 2
    },{
        "index": 208,
        "urlSingle": "crea-rifa-ergoraffle",
        "titulo": "Crea una rifa en ErgoRaffle",
        "descripcion": "Crea una rifa en Ergo Raffle y participa en otra existente.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/U0T810o35Nw",
        "github": "https://github.com/ErgoRaffle/raffle-frontend",
        "web": "https://beta.ergoraffle.com/",
        "youtube": "https://www.youtube.com/watch?v=U0T810o35Nw",
        "idioma": 2
    },{
        "index": 207,
        "urlSingle": "redimir-sigmausd",
        "titulo": "Redimir SigmaUSD",
        "descripcion": "En este tutorial vamos a redimir 100 SigmaUSD por ERG.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/0KjgCS4FCKI",
        "github": "https://github.com/anon-real/sigma-usd",
        "web": "https://sigmausd.io/#/",
        "youtube": "https://www.youtube.com/watch?v=0KjgCS4FCKI",
        "idioma": 2
    },{
        "index": 206,
        "urlSingle": "envio-token-airdrop",
        "titulo": "Envío de token con Airdrop",
        "descripcion": "Enviaremos 10 TutorialsCoin a 6 direcciones de forma simultanea.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/6AmCbClZZP4",
        "github": "https://github.com/anon-real/ErgoUtils",
        "web": "https://ergoutils.org/#/airdrop",
        "youtube": "https://www.youtube.com/watch?v=6AmCbClZZP4",
        "idioma": 2
    },{
        "index": 205,
        "urlSingle": "crear-token-ergoutils",
        "titulo": "Crear token con Ergo Utils",
        "descripcion": "Crearemos un token con 1000000 de unidades llamado TutorialsCoin con Ergo Utils.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/gOriT1rzxF0",
        "github": "https://github.com/anon-real/ErgoUtils",
        "web": "https://ergoutils.org",
        "youtube": "https://www.youtube.com/watch?v=gOriT1rzxF0",
        "idioma": 2
    },{
        "index": 204,
        "urlSingle": "intercambio-ergodex",
        "titulo": "Intercambio con ErgoDex",
        "descripcion": "Intercambio de 100.000 Kushtis por 8 Erdoge a través de ErgoDex.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/X5bJ8nKQ7eg",
        "github": "https://github.com/ergolabs",
        "web": "https://ergodex.io",
        "youtube": "https://www.youtube.com/watch?v=X5bJ8nKQ7eg",
        "idioma": 2
    },{
        "index": 203,
        "urlSingle": "instalar-extension-yoroi",
        "titulo": "Instalar extensión Yoroi y crear una billetera",
        "descripcion": "Vamos a instalar la extensión Yoroi para crear una cartera paso a paso.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/fLtphS_Sz-s",
        "github": "https://github.com/Emurgo/yoroi-frontend",
        "web": "https://yoroi-wallet.com/#/",
        "youtube": "https://www.youtube.com/watch?v=fLtphS_Sz-s",
        "idioma": 2
    },{
        "index": 202,
        "urlSingle": "mezclar-tokens-ergomixer",
        "titulo": "Cómo mezclar tokens con ErgoMixer",
        "descripcion": "En este tutorial podrás aprender a descargar ErgoMixer y a mezclar fácilmente tokens de Ergo.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/-D-cfedo3fs",
        "github": "https://github.com/ergoMixer/ergoMixBack/releases",
        "web": "https://ergoplatform.org/en/software/",
        "youtube": "https://www.youtube.com/watch?v=-D-cfedo3fs",
        "idioma": 2
    },{
        "index": 201,
        "urlSingle": "consulta-basica-api-ergo",
        "titulo": "Consulta básica API Ergo",
        "descripcion": "Rescata el valor de Creation Height del blockchain de Ergo y modifica el color de fondo de tu sitio web con su valor.",
        "categoria": "Desarrollo (HTML + JS)",
        "incrustado": "https://www.youtube.com/embed/BnoWZHCxUho",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-1",
        "web": "https://github.com/ladopixel/ErgoTutorials.com/blob/master/tutorial-1/index.html",
        "youtube": "https://www.youtube.com/watch?v=BnoWZHCxUho",
        "idioma": 2
    }]
    return arrayVideosES
}