import React from 'react'
import {
    Box,
    HStack,
    Text,
  } from '@chakra-ui/react';

import InfoGeneral from './infoGeneral';
import Banner from './banner';

export default function Header(){
    return(
        <>
            <HStack>
                <Box w={'100%'}>
                    <InfoGeneral />
                </Box>
            </HStack>
        </>
    )
}