import React from 'react';
import {
  ChakraProvider,
  Box,
  Center,
  Flex,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
} from '@chakra-ui/react';

import Header from './components/header'
import Opciones from './components/opciones'
import Footer from './components/footer';
import NavBar from './components/navBar';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Center>
        <Box m={10}>
            <Header />
            <NavBar />
            <Footer />
        </Box>
      </Center>
    </ChakraProvider>
  );
}

export default App;
