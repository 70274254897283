import React from 'react'
import {
    Box,
    HStack,
    Link,
    Text,
} from '@chakra-ui/react';
import { AiFillGithub } from "react-icons/ai"
import { FaYoutube } from "react-icons/fa"
import { GrTwitter } from "react-icons/gr"

export default function Footer (){
    return(
        <Box fontSize={'small'} mt={3}>
            <HStack>
                <Text>ErgoTutorials.com</Text>
                <Link href={'https://github.com/ladopixel/ergotutorials.com'} isExternal>
                    <AiFillGithub color={'gray'} />
                </Link>
                <Link href={'https://youtube.com/ErgoTutorialscom'} color={'gray'} isExternal>
                    <FaYoutube />
                </Link>
                <Link href={'https://twitter.com/ergoTutorials/'} color={'gray'} isExternal>
                    <GrTwitter />
                </Link>
                <Text>enjoy with Ergo</Text>
                <Text color={'#53caf0'}> ♥ </Text> 
            </HStack>
         </Box>
    )
}