import React from 'react'
import {
    Box,
    HStack,
    Text,
  } from '@chakra-ui/react';

export default function About(){
    return(
        <>
            <Box w={'100%'} h={'400px'} bgColor={'gray'} my={2}>
                Estoy en About
            </Box>
            
        </>
    )
}