import React, { Component } from 'react'
import {
    AspectRatio,
    Badge,
    Box,
    Button,
    Divider,
    Heading,
    HStack,
    Text,
    useColorModeValue,
    VStack,
  } from '@chakra-ui/react'

import ReactPlayer from 'react-player'

import { SeleccionoMensaje, mostrarUrlGithub, mostrarUrlWeb } from '../recursos/funciones'
import { Link, useLocation } from 'wouter'
import { FaWindowMaximize } from "react-icons/fa"


export default function Card({idioma, urlSingle, urlVideo, titulo, categoria, descripcion, urlGithub, urlYoutube, urlWeb}){
    const fondoCarta = useColorModeValue('#F7FAFC', '#171923')
    const tituloCarta = useColorModeValue('black', 'white')
    const cuerpoCarta = useColorModeValue('#f3f6f9', '#1A202C')
    const barraIconos = useColorModeValue('#f7f7f7', '#2D3748')
    
    return(
        <>
            <Box bgColor={fondoCarta} borderRadius={10} m={1} p={3} borderColor={'white'} >
                {/* <AspectRatio ratio={16 / 9}>
                    <ReactPlayer url={urlVideo} width={'auto'} height={'auto'} controls={true}/>
                </AspectRatio> */}
                <AspectRatio maxW='660px' ratio={16 / 9}>
                    <iframe
                        title={titulo}
                        src={urlVideo}
                        allowFullScreen
                    />
                </AspectRatio>
                
                
                <Badge variant='outline'colorScheme='gray' m={2}>{categoria} </Badge>
                
                
                <Box bgColor={cuerpoCarta} p={2} borderRadius={10}>
                    
                    <VStack align='start'>
                        <Heading size='md' mt={3} color={tituloCarta}>{titulo}</Heading>
                        <Text color='gray' py={2}>{descripcion}</Text>
                    </VStack>

                    <Divider my={3} m={0} bgColor={'#bababa'}/>
                    
                    <Box bgColor={barraIconos}>
                        <Button bgColor={'transparent'}>
                            <Link to={`/video/${urlSingle}`} >
                                <FaWindowMaximize color={'gray'} />
                            </Link>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}