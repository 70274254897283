import React from 'react'
import {
    AspectRatio,
    Box,
    Center,
    Heading,
    HStack,
    Image,
    Link,
    Text,
    useColorModeValue,
    VStack,
  } from '@chakra-ui/react';
import ReactPlayer from 'react-player'
import { ExternalLinkIcon } from '@chakra-ui/icons'

import IMGErgoManifesto from '../recursos/ergomanifesto.png'

export default function Manifesto(){

    const colorDescripcion = useColorModeValue('gray.600', 'gray.400')
    const fondoAudios = useColorModeValue('#f3f6f9', '#1A202C')
    const colorEnlace = useColorModeValue('black', 'white')
    const cuerpoCarta = useColorModeValue('#F7FAFC', '#171923')

    return(
        <>
                   
                <Box w={'100%'}
                    bgColor={cuerpoCarta}
                    borderRadius={10}
                    mt={4} p={2}
                    mb={4}
                >
                    <Box bgColor={fondoAudios} p={2} mt={3}>
                        <Heading 
                            mt={5}
                        >The Ergo Manifesto</Heading>
                        
                        <Text py={4} color={colorDescripcion} fontSize={'1xl'}>
                        The Ergo Manifesto hopes to educate and offer a vision of what blockchain technology can achieve. 
                        We hope to build society through horizontal cooperation through production under the division of labor, trade and exchange, and solidarity and mutual aid.
                        </Text>
            
                        <Text color={colorDescripcion}>You can read on the 
                            <Link 
                                href={'https://ergoplatform.org/en/blog/2021-04-26-the-ergo-manifesto/'} 
                                textDecoration={'underline'} 
                                mx={2} 
                                color={colorEnlace} 
                                isExternal
                                >
                                    Ergo Platform<ExternalLinkIcon mx={2} />
                                    <Image 
                                        alt={'Ergo Manifesto'} 
                                        borderRadius={10} 
                                        mt={3}
                                        src={IMGErgoManifesto} 
                                        w={'50%'} 
                                    />
                            </Link>
                        </Text>
                        <Text fontSize='xs' color={colorDescripcion}>(Text and image extracted from the official Ergo website)</Text>
                    </Box>

                    <Box bgColor={fondoAudios} p={2} mt={3}>
                        <Heading 
                            mt={5}
                        >Listen The Ergo Manifesto</Heading>
                        <Text 
                            color={colorDescripcion}
                            my={3}
                        >
                            If you don't feel like reading now, you can listen to it.
                        </Text>
                        
                                
                        <Heading my={3} w={'80%'} fontSize='2xl'>In English</Heading>
                        <ReactPlayer 
                            controls={true}
                            height={'100px'} 
                            url={'https://soundcloud.com/ergotutorials/the-ergo-manifesto'} 
                            width={'50%'}
                        />
                    
                        <Heading my={3} w={'80%'} fontSize='2xl'>En español</Heading>
                        <ReactPlayer 
                            controls={true}
                            height={'100px'} 
                            url={'https://soundcloud.com/ergotutorials/el-manifiesto-de-ergo'} 
                            width={'50%'}
                        />
                    </Box>    
                       
                        
                </Box>
            
            
        </>
    )
}