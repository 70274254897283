import React from "react";
import {
Box,
Flex,
Heading,
Image,
Menu,
Stack,
Text,
useColorModeValue,
useDisclosure,
VStack,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link } from 'wouter'

import ImageLogo from '../recursos/Ergo-black.png'
import Contenido from "./contenido";
import { ColorModeSwitcher } from '../ColorModeSwitcher';

// Note: This code could be better,
// so I'd recommend you to understand how I solved and you could write yours better :)
// Good luck! 🍀

// Update: Check these awesome headers from Choc UI 👇
// https://choc-ui.tech/docs/elements/headers



const NavBar = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const handleToggle = () => (isOpen ? onClose() : onOpen());

    const fondoLogo = useColorModeValue('#fff', '#2D3748')
    const fondoBarraOpcion = useColorModeValue('#F7FAFC', '#2d3648')
    const opcionManifesto = useColorModeValue('#EDF2F7', '#1A202C')

  return (
    <Box>
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            color="gray"
            {...props}
        >   
            <Box bgColor={fondoLogo} mr={2} borderRadius={10}>
                <Flex align="center" mr={5}>
                    <Heading as="h1" size="lg" letterSpacing={"tighter"}>
                        <Image src={ImageLogo} w={'100px'}/>
                    </Heading>
                </Flex>
            </Box>

            <Box display={{ base: "block", md: "none" }} onClick={handleToggle}>
                <HamburgerIcon />
            </Box>
            
            

            <Stack
                alignItems="center"
                flexGrow={1}
                direction={{ base: "column", md: "row" }}
                display={{ base: isOpen ? "block" : "none", md: "flex" }}
                mt={{ base: 4, md: 0 }}
                width={{ base: "full", md: "auto" }}
                
                borderRadius={10}
                bgColor={fondoBarraOpcion}
            >
                <Menu>
                    <Text fontWeight={'light'} pl={3}><Link href={'/english'}>EN</Link></Text>
                    <Text fontWeight={'light'} pl={3}><Link href={'/spanish'}>ES</Link></Text>
                    <Text fontWeight={'light'} pl={3}><Link href={'/portuguese'} >PT-BR</Link></Text>
                    <Text fontWeight={'light'} pl={3}><Link href={'/deutsch'} >DE</Link></Text>
                    <Text fontWeight={'light'} pl={3}><Link href={'/italian'} >IT</Link></Text>
                    <Text fontWeight={'light'} pl={3}><Link href={'/french'} >FR</Link></Text>
                    <Text fontWeight={'light'} pl={3}><Link href={'/translations'} >Translations</Link></Text>
                    
                    <Text 
                        bgColor={opcionManifesto}
                        border={'1px'}
                        borderRadius={5}
                        borderColor={fondoBarraOpcion}
                        fontWeight={'light'} 
                        p={2.5} 
                    
                    ><Link href={'/ergo-manifesto'} >Ergo Manifesto</Link></Text>
                    <ColorModeSwitcher />
                </Menu>
                
            </Stack>
            <Box
                display={{ base: isOpen ? "block" : "none", md: "block" }}
                mt={{ base: 4, md: 0 }}
            >
            </Box>
            
        </Flex>
    
        <Contenido />

    </Box>
  )
}

export default NavBar