import React, {useEffect, useState} from 'react'
import {
    AspectRatio,
    Box,
    Center,
    Button,
    Heading,
    HStack,
    Link,
    Text,
    useColorModeValue,
  } from '@chakra-ui/react';

import { useRoute } from 'wouter'
import { VscTwitter } from "react-icons/vsc"
import { AiFillYoutube } from "react-icons/ai"

import { arrayVideosEN } from '../recursos/arrayVideoEN';
import { arrayVideosES } from '../recursos/arrayVideosES';
import { arrayVideosPTBR } from '../recursos/arrayVideosPTBR';
import { arrayVideosDE } from '../recursos/arrayVideosDE';
import { arrayVideosIT } from '../recursos/arrayVideosIT';
import { arrayVideosFR } from '../recursos/arrayVideosFR';

import { SeleccionoMensaje, mostrarUrlGithub, mostrarUrlWeb } from '../recursos/funciones'

export default function SingleVideo(){
    
    const [listado, setListado] = useState('loading...')
    const [match, params] = useRoute("/video/:singleVideo");

    const colorDescripcion = useColorModeValue('gray.600', 'gray.400')
    const colorEnlace = useColorModeValue('black', 'white')
    const cuerpoCarta = useColorModeValue('#F7FAFC', '#171923')
    
    useEffect(() => {
        let videos = arrayVideosEN().length
        let videosES = arrayVideosES().length
        let videosPTBR = arrayVideosPTBR().length
        let videosDE = arrayVideosDE().length
        let videosIT = arrayVideosIT().length
        let videosFR = arrayVideosFR().length

        for (let i = 0; i < videos; i++){
            if(arrayVideosEN()[i].urlSingle == params.singleVideo){
                setListado(arrayVideosEN()[i])
            }
        }
        for (let i = 0; i < videosES; i++){
            if(arrayVideosES()[i].urlSingle == params.singleVideo){
                setListado(arrayVideosES()[i])
            }
        }
        for (let i = 0; i < videosPTBR; i++){
            if(arrayVideosPTBR()[i].urlSingle == params.singleVideo){
                setListado(arrayVideosPTBR()[i])
            }
        }
        for (let i = 0; i < videosDE; i++){
            if(arrayVideosDE()[i].urlSingle == params.singleVideo){
                setListado(arrayVideosDE()[i])
            }
        }
        for (let i = 0; i < videosIT; i++){
            if(arrayVideosIT()[i].urlSingle == params.singleVideo){
                setListado(arrayVideosIT()[i])
            }
        }
        for (let i = 0; i < videosFR; i++){
            if(arrayVideosFR()[i].urlSingle == params.singleVideo){
                setListado(arrayVideosFR()[i])
            }
        }

    }, [])
    

    return(
        <>  
            <Box bgColor={cuerpoCarta} p={2} mt={4} mb={4} borderRadius={10} w={'100%'}>
                <Heading mt={5}>{listado.titulo}</Heading>
                   
                <Text py={4} color={colorDescripcion} fontSize={'1xl'}>
                    {listado.descripcion}
                </Text>
                
                <HStack pt={3}>
                    <Button bgColor={'transparent'}>
                        <Link href={SeleccionoMensaje(listado.idioma, listado.youtube)} isExternal>
                            <VscTwitter color={'#0ecaf0'} />
                        </Link>
                    </Button>
                    <Button bgColor={'transparent'}>
                        <Link href={listado.youtube} isExternal >
                            <AiFillYoutube color={'#dc3545'} />
                        </Link>
                    </Button>
                    <Button bgColor={'transparent'}>
                        <Link href={listado.github} isExternal>
                            {mostrarUrlGithub(listado.github)}
                        </Link>
                    </Button>

                    <Button bgColor={'transparent'}>
                        <Link href={listado.web} isExternal>
                            {mostrarUrlWeb(listado.web)}
                        </Link> 
                    </Button>
                </HStack>

                <Box 
                    borderRadius={10} 
                    m={1} p={2}
                >
                    {/* <AspectRatio ratio={16 / 9}>
                        <ReactPlayer 
                            url={listado.incrustado} 
                            w={'auto'}
                            maxW='200px'
                            height={'auto'} 
                            controls={true}
                        />
                    </AspectRatio> */}
                    
                    <AspectRatio maxW='560px' ratio={16 / 9}>
                        
                            <iframe
                                title={listado.titulo}
                                src={listado.incrustado}
                                allowFullScreen
                            />
                        
                    </AspectRatio>
                </Box>
                
            </Box>
        </>
    )
}