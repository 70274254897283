export function arrayVideosEN(){
    const arrayVideosEN = [{
        "index": 28,
        "urlSingle": "rosen-bridge",
        "titulo": "Rosen Bridge Watcher with Mac",
        "descripcion": "In this tutorial we will go through the process to participate as a watcher in the Rosen Bridge.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/LQNdpmpQmH0",
        "github": "https://github.com/rosen-bridge/operation/blob/dev/docs/watcher/deploy-docker.md",
        "web": "https://rosen.tech",
        "youtube": "https://www.youtube.com/watch?v=LQNdpmpQmH0",
        "idioma": 1
    },{
        "index": 27,
        "urlSingle": "auction-house-v3",
        "titulo": "Auction House v3",
        "descripcion": "In this tutorial we will see the Auction House V3, its new features, we will mint an NFT and we will list it.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/i-QS0oN--LI",
        "github": "",
        "web": "https://ergoauctions.org/",
        "youtube": "https://www.youtube.com/watch?v=i-QS0oN--LI",
        "idioma": 1
    },{
        "index": 26,
        "urlSingle": "create-token-python",
        "titulo": "Create token with Python",
        "descripcion": "In this tutorial we are going to use Python to interact with the Ergo blockchain, we will create 1000 units of a token.",
        "categoria": "Development (Python)",
        "incrustado": "https://www.youtube.com/embed/VMLkiyeotPE",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/blob/master/tutorial-26/create-token.py",
        "web": "https://github.com/mgpai22/ergpy",
        "youtube": "https://www.youtube.com/watch?v=VMLkiyeotPE",
        "idioma": 1
    },{
        "index": 25,
        "urlSingle": "send-erg-python",
        "titulo": "Sending ERG with Python",
        "descripcion": "In this tutorial we are going to use Python (ergpy) to interact with the Ergo blockchain, we will send the amount of 0.1 ERG.",
        "categoria": "Development (Python)",
        "incrustado": "https://www.youtube.com/embed/-zQs6i5l2yE",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/blob/master/tutorial-25/send-ergs.py",
        "web": "https://github.com/mgpai22/ergpy",
        "youtube": "https://www.youtube.com/watch?v=-zQs6i5l2yE",
        "idioma": 1
    },{
        "index": 24,
        "urlSingle": "market-nfts-skyharbor",
        "titulo": "Buy and sell NFTs at SkyHarbor",
        "descripcion": "In this tutorial we will see how to buy and sell NFTs on SkyHarbor.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/E4GzCzPeip4",
        "github": "",
        "web": "https://www.skyharbor.io/",
        "youtube": "https://www.youtube.com/watch?v=E4GzCzPeip4",
        "idioma": 1
    },{
        "index": 23,
        "urlSingle": "ergo-node-mac",
        "titulo": "Ergo node with Mac",
        "descripcion": "In this tutorial we will see how to run the Ergo node with Mac",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/b6nfgMBoVWg",
        "github": "https://github.com/ergoplatform/ergo/releases/",
        "web": "https://docs.ergoplatform.com/node/install/",
        "youtube": "https://www.youtube.com/watch?v=b6nfgMBoVWg",
        "idioma": 1
    },{
        "index": 22,
        "urlSingle": "ergo-mobile-wallet",
        "titulo": "Use Ergo Mobile Wallet",
        "descripcion": "In this tutorial we will use the Ergo Mobile Wallet app for Android. We are going to create a new wallet, restore another one from the seed phrase, and also add a read-only wallet address.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/zxJ00gZEw9Y",
        "github": "https://github.com/ergoplatform/ergo-wallet-app",
        "web": "http://old.ergoplatform.org/en/mobile_wallets/",
        "youtube": "https://www.youtube.com/watch?v=zxJ00gZEw9Y",
        "idioma": 1
    },{
        "index": 21,
        "urlSingle": "verify-nfts-artists",
        "titulo": "Verify NFT Artists",
        "descripcion": "In this tutorial we will see how to verify NFT artists in Ergo.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/FkdPdlVB1yY",
        "github": "https://github.com/anon-real/ErgoAuctionHouse",
        "web": "https://ergoauctions.org/",
        "youtube": "https://www.youtube.com/watch?v=FkdPdlVB1yY",
        "idioma": 1
    },{
        "index": 20,
        "urlSingle": "buy-sigmausd-sigmarsv",
        "titulo": "Buy SigmaUSD and SigmaRSV",
        "descripcion": "In this tutorial we will see how to buy SigmaUSD and SigmaRSV.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/Vvh51GwAfyc",
        "github": "https://github.com/anon-real/sigma-usd",
        "web": "https://sigmausd.io",
        "youtube": "https://www.youtube.com/watch?v=Vvh51GwAfyc",
        "idioma": 1
    },{
        "index": 19,
        "urlSingle": "paper-wallet",
        "titulo": "Use Ergo Paper Wallet",
        "descripcion": "In this tutorial we will see how to create a paper wallet without an internet connection.",
        "categoria": "General",
        "incrustado": "https://youtube.com/embed/0qTasq-nSNw",
        "github": "https://github.com/anon-br/ergo-paper-wallet",
        "web": "https://ergopaperwallet.org/",
        "youtube": "https://youtube.com/watch?v=0qTasq-nSNw",
        "idioma": 1
    },{
        "index": 18,
        "urlSingle": "stake-ergopad",
        "titulo": "Swap and stake ErgoPad",
        "descripcion": "In this tutorial we will see how to swap ERGs for ErgoPad from ErgoDex and how to staking them.",
        "categoria": "General",
        "incrustado": "https://youtube.com/embed/3utoKRu5yAg",
        "github": "https://github.com/ergo-pad",
        "web": "https://ergopad.io/",
        "youtube": "https://youtube.com/watch?v=3utoKRu5yAg",
        "idioma": 1
    },{
        "index": 17,
        "urlSingle": "use-safew",
        "titulo": "Download and use SafeW",
        "descripcion": "In this tutorial we will download SafeW, create one wallet, restore another and use both wallets.",
        "categoria": "General",
        "incrustado": "https://youtube.com/embed/DFf-dDlCpzM",
        "github": "https://github.com/ThierryM1212/SAFEW",
        "web": "https://ergoplatform.org/en/wallets/",
        "youtube": "https://youtube.com/watch?v=DFf-dDlCpzM",
        "idioma": 1
    },{
        "index": 16,
        "urlSingle": "ergodex-with-nautilus",
        "titulo": "ErgoDex with Nautilus Wallet",
        "descripcion": "In this tutorial we will see how to use ErgoDex with the Nautilus Wallet connector, previously removing the permission to the Yoroi connector.",
        "categoria": "General",
        "incrustado": "https://youtube.com/embed/UCgiS4GRqkE",
        "github": "https://github.com/capt-nemo429/nautilus-wallet/releases/",
        "web": "https://app.ergodex.io/",
        "youtube": "https://youtube.com/watch?v=UCgiS4GRqkE",
        "idioma": 1
    },{
        "index": 15,
        "urlSingle": "install-nautilus",
        "titulo": "Install Nautilus extension and create a wallet",
        "descripcion": "In this tutorial we will see how to install the Nautilus Wallet extension and create a wallet.",
        "categoria": "General",
        "incrustado": "https://youtube.com/embed/hMwtwlUpnRQ",
        "github": "https://github.com/capt-nemo429/nautilus-wallet/releases/tag/0.1.7-beta",
        "web": "https://ergoplatform.org/en/wallets/",
        "youtube": "https://youtube.com/watch?v=hMwtwlUpnRQ",
        "idioma": 1
    },{
        "index": 14,
        "urlSingle": "configuring-ergotipperbot",
        "titulo": "Configuring ErgoTipperBot",
        "descripcion": "In this tutorial we are going to configure and send a tip with ErgoTipperBot.",
        "categoria": "General",
        "incrustado": "https://youtube.com/embed/vsB6RoY_7Z8",
        "github": "https://github.com/Luivatra/ergotipperbackend",
        "web": "https://twitter.com/ErgoTipperBot",
        "youtube": "https://youtube.com/watch?v=vsB6RoY_7Z8",
        "idioma": 1
    },{
        "index": 13,
        "urlSingle": "create-auction-v2",
        "titulo": "Create auction on Ergo Auctions V2",
        "descripcion": "In this tutorial we will see how to easily mint an NFT and how to put it up for auction and direct sale in the Ergo Auction House V2.",
        "categoria": "General",
        "incrustado": "https://youtube.com/embed/Shm7FF0DNk8",
        "github": "https://github.com/anon-real/ErgoAuctionHouse",
        "web": "https://ergoauctions.org/",
        "youtube": "https://youtube.com/watch?v=Shm7FF0DNk8",
        "idioma": 1
    },{
        "index": 12,
        "urlSingle": "withdraw-gateio",
        "titulo": "Withdraw ERG from Gate.io",
        "descripcion": "In this tutorial we will withdraw ERG from Gate.io and transfer it to a Yoroi wallet.",
        "categoria": "General",
        "incrustado": "https://youtube.com/embed/bgDqS5NMUVM",
        "github": "https://ergoplatform.org/en/exchanges/",
        "web": "https://ergoplatform.org/en/exchanges/",
        "youtube": "https://youtube.com/watch?v=bgDqS5NMUVM",
        "idioma": 1
    },{
        "index": 11,
        "urlSingle": "buy-erg-gateio",
        "titulo": "Buy Erg at Gate.io",
        "descripcion": "In this tutorial we will buy Erg on Gate.io by sending us USDT through Binance.",
        "categoria": "General",
        "incrustado": "https://youtube.com/embed/nxzfoY3QQUM",
        "github": "https://ergoplatform.org/en/exchanges/",
        "web": "https://ergoplatform.org/en/exchanges/",
        "youtube": "https://youtube.com/watch?v=nxzfoY3QQUM",
        "idioma": 1
    },{
        "index": 10,
        "urlSingle": "use-ergowalletsorg",
        "titulo": "Use ErgoWallets.org",
        "descripcion": "Different ways to view the content of your wallet: balance, tokens and NFTs. The wallets for the example have been chosen at random.",
        "categoria": "General",
        "incrustado": "https://youtube.com/embed/F2OGYgUPJ4Q",
        "github": "https://github.com/ladopixel/ergowallets",
        "web": "https://ergowallets.org",
        "youtube": "https://youtube.com/watch?v=F2OGYgUPJ4Q",
        "idioma": 1
    },{
        "index": 9,
        "urlSingle": "create-mini-web",
        "titulo": "Create mini web for your NFT",
        "descripcion": "In this tutorial we are going to create a mini website to show the NFT that you like the most.",
        "categoria": "Development (HTML + JS)",
        "incrustado": "https://youtube.com/embed/mP6D9Pf6p88",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-2",
        "web": "https://github.com/ladopixel/ErgoTutorials.com/blob/master/tutorial-2/index.html",
        "youtube": "https://youtube.com/watch?v=mP6D9Pf6p88",
        "idioma": 1
    },{
        "index": 8,
        "urlSingle": "create-raffle",
        "titulo": "Create a raffle in ErgoRaffle",
        "descripcion": "Create a raffle in Ergo Raffle as an example and participate.",
        "categoria": "General",
        "incrustado": "https://youtube.com/embed/wmzQV0ZTUWI",
        "github": "https://github.com/ErgoRaffle/raffle-frontend",
        "web": "https://beta.ergoraffle.com/",
        "youtube": "https://youtube.com/watch?v=wmzQV0ZTUWI",
        "idioma": 1
    },{
        "index": 7,
        "urlSingle": "redeem-sigmausd",
        "titulo": "Redeem SigmaUSD",
        "descripcion": "In this tutorial we are going to redeem 100 SigmaUSD for ERG.",
        "categoria": "General",
        "incrustado": "https://youtube.com/embed/DEWYEZUSbYQ",
        "github": "https://github.com/anon-real/sigma-usd",
        "web": "https://sigmausd.io/#/",
        "youtube": "https://youtube.com/watch?v=DEWYEZUSbYQ",
        "idioma": 1
    },{
        "index": 6,
        "urlSingle": "send-token-airdrop",
        "titulo": "Send token with Airdrop",
        "descripcion": "We will send 10 TutorialsCoin to 6 addresses simultaneously.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/XiAp5XioHaQ",
        "github": "https://github.com/anon-real/ErgoUtils",
        "web": "https://ergoutils.org/#/airdrop",
        "youtube": "https://www.youtube.com/watch?v=XiAp5XioHaQ",
        "idioma": 1
    },{
        "index": 5,
        "urlSingle": "create-token-ergoutils",
        "titulo": "Create token with Ergo Utils",
        "descripcion": "We will create a token with 1000000 units called TutorialsCoin with Ergo Utils.",
        "categoria": "General",
        "incrustado": "https://youtube.com/embed/I3R6_PceM1k",
        "github": "https://github.com/anon-real/ErgoUtils",
        "web": "https://ergoutils.org",
        "youtube": "https://youtube.com/watch?v=I3R6_PceM1k",
        "idioma": 1
    },{
        "index": 4,
        "urlSingle": "swap-with-ergodex",
        "titulo": "Swap with ErgoDex",
        "descripcion": "Swap of 100,000 Kushtis for 8 Erdoge through ErgoDex.",
        "categoria": "General",
        "incrustado": "https://youtube.com/embed/2MVawkjuXiw",
        "github": "https://github.com/ergolabs",
        "web": "https://ergodex.io",
        "youtube": "https://youtube.com/watch?v=2MVawkjuXiw",
        "idioma": 1
    },{
        "index": 3,
        "urlSingle": "mix-tokens-ergomixer",
        "titulo": "How to mix tokens with ErgoMixer",
        "descripcion": "In this tutorial you will learn how to download ErgoMixer and easily mix Ergo tokens.",
        "categoria": "General",
        "incrustado": "https://youtube.com/embed/T9M6j6xfx4w",
        "github": "https://github.com/ergoMixer/ergoMixBack/releases",
        "web": "https://ergoplatform.org/en/software/",
        "youtube": "https://youtube.com/watch?v=T9M6j6xfx4w",
        "idioma": 1
    },{
        "index": 2,
        "urlSingle": "install-yoroi",
        "titulo": "Install Yoroi extension and create a wallet",
        "descripcion": "We will install the Yoroi extension to create a wallet step by step.",
        "categoria": "General",
        "incrustado": "https://youtube.com/embed/u05mUkNbVl8",
        "github": "https://github.com/Emurgo/yoroi-frontend",
        "web": "https://yoroi-wallet.com/#/",
        "youtube": "https://youtube.com/watch?v=u05mUkNbVl8",
        "idioma": 1
    },{
        "index": 1,
        "urlSingle": "ergo-api-query",
        "titulo": "Ergo API basic query",
        "descripcion": "Retrieve the Creation Height value from the Ergo blockchain and modify the background color of your website with its value.",
        "categoria": "Development (HTML + JS)",
        "incrustado": "https://youtube.com/embed/B3W9uNwk_DM",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-1",
        "web": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-1",
        "youtube": "https://youtube.com/watch?v=B3W9uNwk_DM",
        "idioma": 1
    }]
    return arrayVideosEN
}