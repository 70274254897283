export function arrayVideosPTBR(){
    const arrayVideosPTBR = [{
        "index": 326,
        "urlSingle": "criar-token-com-python",
        "titulo": "Criar token com Python",
        "descripcion": "Neste tutorial vamos usar Python para interagir com a Ergo blockchain, vamos criar 1000 unidades de um token.",
        "categoria": "Desarrollo (Python)",
        "incrustado": "https://www.youtube.com/embed/8RUmtYd6RF4",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-26",
        "web": "https://github.com/mgpai22/ergpy",
        "youtube": "https://www.youtube.com/watch?v=8RUmtYd6RF4",
        "idioma": 3
    },{
        "index": 325,
        "urlSingle": "enviando-erg-com-python",
        "titulo": "Enviando ERG com Python",
        "descripcion": "Neste tutorial vamos usar Python (ergpy) para interagir com a Ergo blockchain enviaremos a quantidade de 0,1 ERG.",
        "categoria": "Desarrollo (Python)",
        "incrustado": "https://www.youtube.com/embed/RkSNUBJw67o",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-25",
        "web": "https://github.com/mgpai22/ergpy",
        "youtube": "https://www.youtube.com/watch?v=RkSNUBJw67o",
        "idioma": 3
    },{
        "index": 324,
        "urlSingle": "compre-e-venda-skyharbor",
        "titulo": "Compre e venda NFTs no SkyHarbor",
        "descripcion": "Neste tutorial veremos como comprar e vender NFTs no SkyHarbor.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/DQ8dwNwgj4o",
        "github": "https://github.com/skyharbor-market",
        "web": "https://www.skyharbor.io/",
        "youtube": "https://www.youtube.com/watch?v=DQ8dwNwgj4o",
        "idioma": 3
    },{
        "index": 323,
        "urlSingle": "no-ergo-com-mac",
        "titulo": "Nó Ergo com Mac",
        "descripcion": "Neste tutorial veremos como executar o nó Ergo com Mac.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/KzUNuKA1RBo",
        "github": "https://github.com/ergoplatform/ergo/releases/",
        "web": "https://www.oracle.com/java/technologies/java-se-glance.html",
        "youtube": "https://www.youtube.com/watch?v=KzUNuKA1RBo",
        "idioma": 3
    },{
        "index": 322,
        "urlSingle": "criar-ergo-mobile-wallet",
        "titulo": "Usar Ergo Mobile Wallet",
        "descripcion": "Neste tutorial usaremos o aplicativo Ergo Mobile Wallet para Android. Vamos criar uma nova carteira, restaurar outra da frase semente e também adicionar um endereço de carteira somente leitura.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/9WbZuqNorts",
        "github": "https://github.com/ergoplatform/ergo-wallet-app",
        "web": "https://ergoplatform.org/en/get-erg/#Wallets",
        "youtube": "https://www.youtube.com/watch?v=9WbZuqNorts",
        "idioma": 3
    },{
        "index": 321,
        "urlSingle": "verificar-nfts-an-ergo",
        "titulo": "Verificar artistas NFT",
        "descripcion": "Neste tutorial veremos como verificar artistas NFT na Ergo.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/eaX2SmkgEuM",
        "github": "https://github.com/anon-real/ErgoAuctionHouse",
        "web": "https://ergoauctions.org/#/auction/active?type=all",
        "youtube": "https://www.youtube.com/watch?v=eaX2SmkgEuM",
        "idioma": 3
    },{
        "index": 320,
        "urlSingle": "baixe-use-safew",
        "titulo": "Baixe e use SafeW",
        "descripcion": "Neste tutorial vamos baixar a SafeW, criar uma carteira, restaurar outra e usar as duas carteiras.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/Axd6KgPOKJA",
        "github": "https://github.com/ThierryM1212/SAFEW",
        "web": "https://ergoplatform.org/en/get-erg/#Wallets",
        "youtube": "https://www.youtube.com/watch?v=Axd6KgPOKJA",
        "idioma": 3
    },{
        "index": 319,
        "urlSingle": "ergodex-carteira-nautilus",
        "titulo": "ErgoDex com carteira Nautilus",
        "descripcion": "Neste tutorial veremos como usar o ErgoDex com o Nautilus Wallet conector, removendo anteriormente a permissão do Yoroi conector. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/686BT3kaySk",
        "github": "https://github.com/spectrum-finance",
        "web": "https://spectrum.fi/",
        "youtube": "https://www.youtube.com/watch?v=686BT3kaySk",
        "idioma": 3
    },{
        "index": 318,
        "urlSingle": "opere-e-stake-ergopad",
        "titulo": "Opere e stake ErgoPad",
        "descripcion": "Neste tutorial veremos como trocar ERGs por ErgoPad de ErgoDex e como staking. ErgoDex agora é Spectrum Finance (Spectrum.fi)",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/9OiCNjuThsY",
        "github": "https://github.com/ergo-pad",
        "web": "https://www.ergopad.io/",
        "youtube": "https://www.youtube.com/watch?v=9OiCNjuThsY",
        "idioma": 3
    },{
        "index": 317,
        "urlSingle": "criar-carteira-paper-wallet",
        "titulo": "Use Ergo Paper Wallet",
        "descripcion": "Neste tutorial veremos como criar uma carteira de papel sem conexão com a internet.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/oMxeZJ8G1Vc",
        "github": "https://github.com/anon-br/ergo-paper-wallet",
        "web": "https://ergopaperwallet.org/",
        "youtube": "https://www.youtube.com/watch?v=oMxeZJ8G1Vc",
        "idioma": 3
    },{
        "index": 316,
        "urlSingle": "compre-sigmausd-sigmarsv",
        "titulo": "Compre SigmaUSD e SigmaRSV",
        "descripcion": "Neste tutorial veremos como comprar SigmaUSD e SigmaRSV.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/hMxyMgyjrTQ",
        "github": "https://github.com/anon-real/sigma-usd",
        "web": "https://sigmausd.io/#/",
        "youtube": "https://www.youtube.com/watch?v=hMxyMgyjrTQ",
        "idioma": 3
    },{
        "index": 315,
        "urlSingle": "instale-extensao-nautilus",
        "titulo": "Instale a extensão Nautilus e crie uma carteira",
        "descripcion": "Neste tutorial veremos como instalar a extensão Nautilus Wallet e criar uma carteira.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/m1ZSUEPD_nk",
        "github": "https://github.com/capt-nemo429/nautilus-wallet/releases",
        "web": "https://ergoplatform.org/en/get-erg/#Wallets",
        "youtube": "https://www.youtube.com/watch?v=m1ZSUEPD_nk",
        "idioma": 3
    },{
        "index": 314,
        "urlSingle": "criar-leilao-na-ergo-auctionsv2",
        "titulo": "Criar leilão na Ergo Auctions V2",
        "descripcion": "Neste tutorial veremos como facilmente emitir uma NFT e como colocá-la em leilão e venda direta na Ergo Auction House V2. Recomendamos a substituição de Yoroi por Nautilus",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/Tl0Sd77K7ug",
        "github": "https://github.com/anon-real/ErgoAuctionHouse",
        "web": "https://ergoauctions.org/",
        "youtube": "https://www.youtube.com/watch?v=Tl0Sd77K7ug",
        "idioma": 3
    },{
        "index": 313,
        "urlSingle": "configurando-o-ergotipperbot",
        "titulo": "Configurando o ErgoTipperBot",
        "descripcion": "Neste tutorial vamos configurar e enviar uma gorjeta com o ErgoTipperBot. Recomendamos a substituição de Yoroi por Nautilus",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/v31kn4il73o",
        "github": "https://github.com/capt-nemo429/nautilus-wallet/releases",
        "web": "https://twitter.com/ErgoTipperBot",
        "youtube": "https://www.youtube.com/watch?v=v31kn4il73o",
        "idioma": 3
    },{
        "index": 312,
        "urlSingle": "sacar-erg-gateio",
        "titulo": "Sacar ERG da Gate.io",
        "descripcion": "Neste tutorial vamos sacar o ERG da Gate.io e transferi-lo para uma carteira Yoroi. Recomendamos a substituição de Yoroi por Nautilus",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/AOdO3wZUMxM",
        "github": "https://github.com/capt-nemo429/nautilus-wallet/releases",
        "web": "https://ergoplatform.org/en/exchanges/",
        "youtube": "https://www.youtube.com/watch?v=AOdO3wZUMxM",
        "idioma": 3
    },{
        "index": 311,
        "urlSingle": "compre-erg-na-gateio",
        "titulo": "Compre Erg no Gate.io",
        "descripcion": "Neste tutorial iremos comprar Erg na Gate.io enviando USDT através da Binance. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/2j832bRucC4",
        "github": "",
        "web": " https://ergoplatform.org/en/exchanges/",
        "youtube": "https://www.youtube.com/watch?v=2j832bRucC4",
        "idioma": 3
    },{
        "index": 310,
        "urlSingle": "utilize-ergowallets",
        "titulo": "Utilize ErgoWallets.org",
        "descripcion": "Diferentes formas de visualizar o conteúdo da sua carteira: saldo, tokens e NFTs. As carteiras para o exemplo foram escolhidas aleatoriamente.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/Lohh0JtLbSw",
        "github": "https://github.com/ladopixel/ErgoWallets.org",
        "web": "https://ergowallets.org",
        "youtube": "https://www.youtube.com/watch?v=Lohh0JtLbSw",
        "idioma": 3
    },{
        "index": 309,
        "urlSingle": "criar-minisite-nft",
        "titulo": "Criar um mini site para suas NFT",
        "descripcion": "Nesse tutorial nós iremos criar um mini site para mostrar as NFTs que você mais gosta. ",
        "categoria": "Desarrollo (HTML + JS)",
        "incrustado": "https://www.youtube.com/embed/shTwFSn3u6o",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-2",
        "web": "https://ergotokens.org/",
        "youtube": "https://www.youtube.com/watch?v=shTwFSn3u6o",
        "idioma": 3
    },{
        "index": 308,
        "urlSingle": "criar-rifa-ergoraffle",
        "titulo": "Criar uma rifa e participar do ErgoRaffle",
        "descripcion": "Nesse tutorial nós iremos criar uma Ergo Rifa como um exemplo e participar em uma rifa existente.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/yISVGkaEprA",
        "github": "https://github.com/ErgoRaffle",
        "web": "https://beta.ergoraffle.com/",
        "youtube": "https://www.youtube.com/watch?v=yISVGkaEprA",
        "idioma": 3
    },{
        "index": 307,
        "urlSingle": "resgatar-sigmausd",
        "titulo": "Resgatar SigmaUSD",
        "descripcion": "Neste tutorial, resgataremos 100 SigmaUSD por ERG.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/cixrsfEvvUE",
        "github": "https://github.com/anon-real/sigma-usd",
        "web": "https://sigmausd.io/#/",
        "youtube": "https://www.youtube.com/watch?v=cixrsfEvvUE",
        "idioma": 3
    },{
        "index": 306,
        "urlSingle": "envie-tokens-airdrop",
        "titulo": "Envie tokens com Airdrop",
        "descripcion": "Enviaremos 10 TutorialsCoin para 6 endereços simultaneamente.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/kHlCWTyLGkk",
        "github": "https://github.com/anon-real/ErgoUtils",
        "web": "https://ergoutils.org/#/airdrop",
        "youtube": "https://www.youtube.com/watch?v=kHlCWTyLGkk",
        "idioma": 3
    },{
        "index": 305,
        "urlSingle": "crie-token-ergoutils",
        "titulo": "Crie um token com o Ergo Utils",
        "descripcion": "Nós iremos criar um token com 1000000 unidades chamado TutorialsCoin com o Ergo Utils.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/TnNlDjO2ftA",
        "github": "https://github.com/anon-real/ErgoUtils",
        "web": "https://ergoutils.org/#/token",
        "youtube": "https://www.youtube.com/watch?v=TnNlDjO2ftA",
        "idioma": 3
    },{
        "index": 304,
        "urlSingle": "swap-ergodex",
        "titulo": "Swap com a ErgoDex",
        "descripcion": "Swap 100000 Kushtis por 8 Erdoge utilizando a ErgoDex. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/h8b0VedQe8U",
        "github": "https://github.com/ergolabs",
        "web": "https://ergodex.io/",
        "youtube": "https://www.youtube.com/watch?v=h8b0VedQe8U",
        "idioma": 3
    },{
        "index": 303,
        "urlSingle": "misturar-tokens-ergomixer",
        "titulo": "Como misturar tokens com o ErgoMixer",
        "descripcion": "Nesse tutorial você irá aprender como fazer o download do ErgoMixer e facilmente misturar tokens Ergo.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/7BXqFm0xhBU",
        "github": "https://github.com/ergoMixer/ergoMixBack/releases",
        "web": "https://ergoplatform.org/en/software/",
        "youtube": "https://www.youtube.com/watch?v=7BXqFm0xhBU",
        "idioma": 3
    },{
        "index": 302,
        "urlSingle": "instalar-extensao-yoroi",
        "titulo": "Instalar a extensão Yoroi e criar uma carteira",
        "descripcion": "Vamos instalar a extensão Yoroi para criar uma carteira passo a passo.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/Zr-ZpKbKzwo",
        "github": "https://github.com/Emurgo/yoroi-frontend",
        "web": "https://chrome.google.com/webstore/detail/yoroi/ffnbelfdoeiohenkjibnmadjiehjhajb/related",
        "youtube": "https://www.youtube.com/watch?v=Zr-ZpKbKzwo",
        "idioma": 3
    },{
        "index": 301,
        "urlSingle": "api-ergo-consulta",
        "titulo": "API Ergo consulta básica",
        "descripcion": "Recupere o valor da Altura de Criação da Ergo blockchain e modifique a cor de fundo de seu website com seu valor.",
        "categoria": "Desarrollo (HTML + JS)",
        "incrustado": "https://www.youtube.com/embed/sMIBTK4kQx4",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-1",
        "web": "https://github.com/ladopixel/ErgoTutorials.com/blob/master/tutorial-1/index.html",
        "youtube": "https://www.youtube.com/watch?v=sMIBTK4kQx4",
        "idioma": 3
    }]
    return arrayVideosPTBR
}