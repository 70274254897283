import React from 'react'
import { Switch, Route } from 'wouter'

import SingleVideo from "./singleVideo";

import En from '../pages/en'
import Es from '../pages/es'
import Ptbr from '../pages/ptbr'
import De from '../pages/de'
import It from '../pages/it'
import Fr from '../pages/fr'

import Translations from '../pages/Translations';
import About from '../pages/about';
import Manifesto from '../pages/manifesto';

export default function Contenido (){
    return(
        <Switch>
            <Route path="/english" component={En} />
            <Route path="/spanish" component={Es} />
            <Route path="/portuguese" component={Ptbr} />
            <Route path="/deutsch" component={De} />
            <Route path="/italian" component={It} />
            <Route path="/french" component={Fr} />

            <Route path="/translations" component={Translations} />
            <Route path="/about" component={About} />
            <Route path="/ergo-manifesto" component={Manifesto} />
            <Route path="/video/:urlSingle" component={SingleVideo} />
            <Route component={En} />
        </Switch>
    )
}