import React, { useEffect, useState } from 'react'
import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
} from '@chakra-ui/react';

import { ArrowForwardIcon } from '@chakra-ui/icons'

const apiURLPrecio = `https://api.nanopool.org/v1/ergo/prices`
const apiURLHight = `https://api.ergoplatform.com/api/v1/networkState`
const apiInfoHash = `https://api.ergoplatform.com/api/v0/info`


export default function InfoGeneral(){

    const [infosErgoHeight, setInfoHeight] = useState('loading...')
    const [infosPrecio, setInfoPrecio] = useState('loading...')
    const [infosHash, setInfoHash] = useState('loading...')
    const [tiempoCarga, setTiempoCarga] = useState(0)

    useEffect(() => {
            fetch(apiURLHight)
                .then(res => res.json())
                .then(response => {
                    const datos = response
                    setInfoHeight(datos.height)
                })
            fetch(apiURLPrecio)
                .then(res => res.json())
                .then(response => {
                    const datos = response
                    let objetoPrecio = {
                        euros: datos.data.price_eur,
                        dolares: datos.data.price_usd
                    }
                    setInfoPrecio(objetoPrecio)
                })
            fetch(apiInfoHash)
                .then(res => res.json())
                .then(response => {
                    const datos = response
                    let objetoInfo = {
                        hashRate: (datos.hashRate/1000000000000).toFixed(2),
                        supply: (datos.supply/1000000000)
                    }
                    setInfoHash(objetoInfo)
                })

                setTimeout(() => {
                    setTiempoCarga(Math.random())
                }, 10000)
        
    }, [tiempoCarga])

    return(
        <> 
            <Stat pl={2} p={'2'} textAlign={'end'} >
                <StatLabel fontSize={'xs'}>Height {infosErgoHeight}</StatLabel>
                <StatLabel fontSize={'xs'}>Supply {infosHash.supply}<ArrowForwardIcon />97739924 ERG</StatLabel>
                <StatNumber>{infosPrecio.euros}€ {infosPrecio.dolares}$</StatNumber>
                <StatHelpText fontSize={'xs'}>
                    Hashrate {infosHash.hashRate} TH/s 
                </StatHelpText>
            </Stat>
        </>
    )
}