export function arrayVideosFR(){
    const arrayVideosFR = [{
        "index": 622,
        "urlSingle": "utiliser-ergo-mobilewallet",
        "titulo": "Utiliser Ergo Mobile Wallet",
        "descripcion": "Dans ce tutoriel, nous allons utiiser l'application Ergo Mobile Wallet pour Android. Nous allons créer un nouveau portefeuille, en restaurer un autre à partir de la seed phrase, et ajouter aussi une adresse de portefeuille en lecture seule.",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/rRkLMUmK9oU",
        "github": "https://github.com/ergoplatform/ergo-wallet-app",
        "web": "https://ergoplatform.org/en/ergo-wallet-app/",
        "youtube": "https://www.youtube.com/watch?v=rRkLMUmK9oU",
        "idioma": 6
    },{
        "index": 621,
        "urlSingle": "verifier-artistes-nft",
        "titulo": "Vérifier les Artistes NFT",
        "descripcion": "Dans ce tutoriel, nous allons voir comment vérifier les artistes NFT sur Ergo",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/wQlxiyiSQas",
        "github": "https://github.com/anon-real/ErgoAuctionHouse",
        "web": "https://ergoauctions.org/",
        "youtube": "https://www.youtube.com/watch?v=wQlxiyiSQas",
        "idioma": 6
    },{
        "index": 620,
        "urlSingle": "acheter-des-sigmausd-sigmarsv",
        "titulo": "Acheter des SigmaUSD et des SigmaRSV",
        "descripcion": "Dans ce tutoriel, nous allons voir comment acheter des SigmaUSD et des SigmaRSV. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/k3cfcMwoK0I",
        "github": "https://github.com/anon-real/sigma-usd",
        "web": "https://sigmausd.io/#/",
        "youtube": "https://www.youtube.com/watch?v=k3cfcMwoK0I",
        "idioma": 6
    },{
        "index": 619,
        "urlSingle": "utiliser-ergo-paperwallet",
        "titulo": "Utiliser Ergo Paper Wallet",
        "descripcion": "Dans ce tutoriel, nous allons voir comment créer un portefeuille papier sans connexion internet. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/a5EgYzfb2tA",
        "github": "https://github.com/anon-br/ergo-paper-wallet",
        "web": "https://ergopaperwallet.org/",
        "youtube": "https://www.youtube.com/watch?v=a5EgYzfb2tA",
        "idioma": 6
    },{
        "index": 618,
        "urlSingle": "staker-des-ergopad",
        "titulo": "Echanger et staker des ErgoPad",
        "descripcion": "Dans ce tutoriel, nous allons voir comment échanger des ERGs contre des ErgoPad depuis ErgoDex (C'est maintenant Spectrum.fi) et comment les staker. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/Np2gnKLJLh4",
        "github": "https://github.com/ergo-pad",
        "web": "https://www.ergopad.io/",
        "youtube": "https://www.youtube.com/watch?v=Np2gnKLJLh4",
        "idioma": 6
    },{
        "index": 617,
        "urlSingle": "telecharger-safew",
        "titulo": "Télécharger et utiliser SafeW",
        "descripcion": "Dans ce tutiriel, nous allons télécharger SafeW, créer un portefeuille, en restorer un et utiliser ces deux portefeuilles. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/w59l7nWqtsA",
        "github": "https://github.com/ThierryM1212/SAFEW",
        "web": "https://ergoplatform.org/en/get-erg/#Wallets",
        "youtube": "https://www.youtube.com/watch?v=w59l7nWqtsA",
        "idioma": 6
    },{
        "index": 616,
        "urlSingle": "ergodex-avec-nautilus",
        "titulo": "ErgoDex avec Nautilus Wallet",
        "descripcion": "Dans ce tutoriel, nous allons voir comment utiliser ErgoDex avec le connecteur de Nautilus Wallet , en supprimant préalablement l'autorisation du connecteur à Yoroi. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/xLNgKUIWiYg",
        "github": "https://github.com/capt-nemo429/nautilus-wallet/releases/",
        "web": "https://ergoplatform.org/en/get-erg/#Wallets",
        "youtube": "https://www.youtube.com/watch?v=xLNgKUIWiYg",
        "idioma": 6
    },{
        "index": 615,
        "urlSingle": "installer-lextension-nautilus",
        "titulo": "Installer l'extension Nautilus et créer un portfeuille",
        "descripcion": "Dans ce tutoriel, nous allons voir comment installer l'extension Nautilus Wallet et créer un portefeuille. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/TrZnMOONfz4",
        "github": "https://github.com/capt-nemo429/nautilus-wallet/releases",
        "web": "https://ergoplatform.org/en/get-erg/#Wallets",
        "youtube": "https://www.youtube.com/watch?v=TrZnMOONfz4",
        "idioma": 6
    },{
        "index": 614,
        "urlSingle": "configurer-ergotipperbot",
        "titulo": "Configurer ErgoTipperBot",
        "descripcion": "Dans ce tutoriel, nous allons configurer et envoyer un pourboire avec ErgoTipperBot. ",
        "categoria": "General",
        "incrustado": "https://www.youtube.com/embed/GETLsnfS_4g",
        "github": "https://github.com/Luivatra/ergotipper-tokens",
        "web": "https://twitter.com/ErgoTipperBot ",
        "youtube": "https://www.youtube.com/watch?v=GETLsnfS_4g",
        "idioma": 6
    },{
        "index": 613,
        "urlSingle": "creer-enchere-ergoauctionsv2",
        "titulo": "Créer une enchère sur Ergo Auction V2",
        "descripcion": "Dans ce tutoriel, nous allons voir comment minter facilement un NFT et comment le publier en tant qu'enchère et vente directe sur Ergo Auction House V2. ",
        "categoria": "Général",
        "incrustado": "https://www.youtube.com/embed/u0IpOB8S-08",
        "github": "https://github.com/anon-real/ErgoAuctionHouse",
        "web": "https://ergoauctions.org/",
        "youtube": "https://www.youtube.com/watch?v=u0IpOB8S-08",
        "idioma": 6
    },{
        "index": 612,
        "urlSingle": "retirer-erg-gateio",
        "titulo": "Retirer des ERG depuis Gate.io",
        "descripcion": "Dans ce tutoriel, nous allons retirer des ERG depuis Gate.io et les transférer à un portefeuille Yoroi. ",
        "categoria": "Général",
        "incrustado": "https://www.youtube.com/embed/xcUpOCCC6d8",
        "github": "https://github.com/capt-nemo429/nautilus-wallet/releases",
        "web": "https://ergoplatform.org/en/exchanges/",
        "youtube": "https://www.youtube.com/watch?v=xcUpOCCC6d8",
        "idioma": 6
    },{
        "index": 611,
        "urlSingle": "acheter-erg-gateio",
        "titulo": "Acheter de l'Erg sur Gate.io",
        "descripcion": "Dans ce tutoriel, nous allons acheter de l'Erg sur Gate.io en envoyant de l'USDT depuis Binance. ",
        "categoria": "Général",
        "incrustado": "https://www.youtube.com/embed/GTXC9heij9E",
        "github": "",
        "web": "https://ergoplatform.org/en/exchanges/",
        "youtube": "https://www.youtube.com/watch?v=GTXC9heij9E",
        "idioma": 6
    },{
        "index": 610,
        "urlSingle": "utiliser-ergowallets",
        "titulo": "Utiliser ErgoWallets.org",
        "descripcion": "Les différents moyens de voir le contenu de votre portefeuille: le solde, les tokens et les NFTs. Pour cet exemple, les portefeuilles ont été choisis au hasard. ",
        "categoria": "Général",
        "incrustado": "https://www.youtube.com/embed/nSLosH9dEa4",
        "github": "https://github.com/ladopixel/ErgoWallets.org",
        "web": "https://ergowallets.org/",
        "youtube": "https://www.youtube.com/watch?v=nSLosH9dEa4",
        "idioma": 6
    },{
        "index": 609,
        "urlSingle": "creer-mini-siteweb",
        "titulo": "Créer un mini site web pour votre NFT",
        "descripcion": "Dans ce tutoriel, nous allons créer un mini site web pour montrer le NFT que vous aimez le plus.",
        "categoria": "Développement",
        "incrustado": "https://www.youtube.com/embed/rjC5z45KIxs",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-2",
        "web": "https://ergotokens.org/ ",
        "youtube": "https://www.youtube.com/watch?v=rjC5z45KIxs",
        "idioma": 6
    },{
        "index": 608,
        "urlSingle": "tombola-ergoraffle",
        "titulo": "Créer une tombola et participer à ErgoRaffle",
        "descripcion": "Dans ce tutoriel, nous allons créer une tombola sur Ergo Raffle en tant qu'exemple et participer à une tombola existante. ",
        "categoria": "Général",
        "incrustado": "https://www.youtube.com/embed/kwo14l_vuoI",
        "github": "",
        "web": "https://ergoraffle.com/",
        "youtube": "https://www.youtube.com/watch?v=kwo14l_vuoI",
        "idioma": 6
    },{
        "index": 607,
        "urlSingle": "echanger-sigmausd",
        "titulo": "Echanger des SigmaUSD",
        "descripcion": "Dans ce tutoriel, nous allons échanger 100 SigmaUSD contre des ERG. ",
        "categoria": "Général",
        "incrustado": "https://www.youtube.com/embed/TxMNUG2iLao",
        "github": "",
        "web": "https://sigmausd.io/ ",
        "youtube": "https://www.youtube.com/watch?v=TxMNUG2iLao",
        "idioma": 6
    },{
        "index": 606,
        "urlSingle": "envoyer-tokens-airdrop",
        "titulo": "Envoyer des tokens avec Airdrop",
        "descripcion": "Nous allons envoyer 10 TutorialsCoins à 6 adresses simultanément. ",
        "categoria": "Général",
        "incrustado": "https://www.youtube.com/embed/njqkxwSDn2s",
        "github": "https://github.com/anon-real/ErgoUtils",
        "web": "https://ergoutils.org/#/airdrop",
        "youtube": "https://www.youtube.com/watch?v=njqkxwSDn2s",
        "idioma": 6
    },{
        "index": 605,
        "urlSingle": "creer-tokens-avec-ergoutils",
        "titulo": "Créer des tokens avec Ergo Utils",
        "descripcion": "Nous allons créer un token de 1000000 d'unités appelé TutorialsCoin avec Ergo Utils.",
        "categoria": "Général",
        "incrustado": "https://www.youtube.com/embed/Xu68ICFZXAQ",
        "github": "https://github.com/anon-real/ErgoUtils",
        "web": "https://ergoutils.org/",
        "youtube": "https://www.youtube.com/watch?v=Xu68ICFZXAQ",
        "idioma": 6
    },{
        "index": 604,
        "urlSingle": "echanges-avec-ergodex",
        "titulo": "Réaliser des échanges avec ErgoDex",
        "descripcion": "Effectuer un échange de 100 000 Kushtis contre 8 Erdoge grâce à ErgoDex. ErgoDex now Spectrum.fi",
        "categoria": "Général",
        "incrustado": "https://www.youtube.com/embed/Pm4Lcqv34nQ",
        "github": "https://github.com/ergolabs",
        "web": "https://app.spectrum.fi/",
        "youtube": "https://www.youtube.com/watch?v=Pm4Lcqv34nQ",
        "idioma": 6
    },{
        "index": 603,
        "urlSingle": "mixer-tokens-avec-ergomixer",
        "titulo": "Comment mixer des tokens avec ErgoMixer",
        "descripcion": "Dans ce tutoriel, vous apprendrez comment télécharger ErgoMixer et mixer facilement des tokens Ergo.",
        "categoria": "Général",
        "incrustado": "https://www.youtube.com/embed/YkfAyrq76Fo",
        "github": "https://github.com/ergoMixer/ergoMixBack/releases",
        "web": "https://github.com/ergoMixer/ergoMixBack/releases",
        "youtube": "https://www.youtube.com/watch?v=YkfAyrq76Fo",
        "idioma": 6
    },{
        "index": 602,
        "urlSingle": "installez-yoroi",
        "titulo": "Installez l'extension Yoroi et créez un portefeuille",
        "descripcion": "Nous allons installer l'extension Yoroi pour créer un portefeuille étape par étape. Nous vous recommandons de remplacer Yoroi par Nautilus.",
        "categoria": "Général",
        "incrustado": "https://www.youtube.com/embed/ZyHt0pKOdgA",
        "github": "https://github.com/capt-nemo429/nautilus-wallet/releases",
        "web": "https://chrome.google.com/webstore/detail/nautilus-wallet/gjlmehlldlphhljhpnlddaodbjjcchai",
        "youtube": "https://www.youtube.com/watch?v=ZyHt0pKOdgA",
        "idioma": 6
    },{
        "index": 601,
        "urlSingle": "requete-base-api",
        "titulo": "Requête de base de l'API Ergo",
        "descripcion": "Récupérez la valeur Creation Height de la blockchain Ergo et modifiez la couleur de fond de votre site web avec sa valeur.",
        "categoria": "Développement",
        "incrustado": "https://www.youtube.com/embed/Gr1j8Msdn88",
        "github": "https://github.com/ladopixel/ErgoTutorials.com/tree/master/tutorial-1",
        "web": "",
        "youtube": "https://www.youtube.com/watch?v=Gr1j8Msdn88",
        "idioma": 6
    }]
    return arrayVideosFR
}