import React, {useEffect, useState} from 'react'
import {
    Box,
    SimpleGrid,
  } from '@chakra-ui/react';
import Card from '../components/card';

import { arrayVideosDE } from '../recursos/arrayVideosDE';

export default function De(){

    const [listadoDE, setListadoDE] = useState([])
    useEffect(() => {
        const listaVideos = arrayVideosDE().map(singleVideo => 
            
                <Card 
                    key={singleVideo.index}
                    urlSingle={singleVideo.urlSingle}
                    idioma={singleVideo.idioma}
                    titulo={singleVideo.titulo}
                    categoria={singleVideo.categoria}
                    descripcion={singleVideo.descripcion}
                    urlGithub={singleVideo.github}
                    urlWeb={singleVideo.web}
                    urlYoutube={singleVideo.youtube}
                    urlVideo={singleVideo.incrustado}
                />
            
        )
        setListadoDE(listaVideos)
    }, [])
    

    return(
        <>  
            <Box p={3}>
                <SimpleGrid columns={[1,2,3]} >
                    {listadoDE}
                </SimpleGrid>
            </Box>
        </>
    )
}